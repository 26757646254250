export const mapConfig = {
	coordinates: {
		latitude: 24.740586099999998,
		longitude: 46.8049391,
		zoom: 15
	},
	styles: {
		border: 0,
		width: "100%",
		height: "353px",
		borderRadius: "16px",
		boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
	}
};


