import React, { useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import routes from "./components/pages/index";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import WhatsAppButton from "./components/WhatsAppButton";

function App() {
	useEffect(() => {
		const isFirstLoad = !sessionStorage.getItem("hasLoaded");

		if (isFirstLoad) {
			sessionStorage.setItem("hasLoaded", "true");

			window.location.reload();
		}

		// Cleanup function
		return () => {
			sessionStorage.removeItem("hasLoaded");
		};
	}, []);

	return (
		<HashRouter>
			<I18nextProvider i18n={i18n}>
				<Routes>
					{routes.map((data, idx) => (
						<Route key={idx} path={data.path} element={<data.component />} />
					))}
				</Routes>
				<WhatsAppButton />
			</I18nextProvider>
		</HashRouter>
	);
}

export default App;
