import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GiProgression } from "react-icons/gi";
import { FaHandshake } from "react-icons/fa";
import { GiThreeFriends } from "react-icons/gi";
const IconBox = ({show}) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const iconbox = [
    {
      id: 1,
      icon: GiProgression,
      title: {
        en: "Progress",
        ar: "التقدم",
      },
      text: {
        en: "We are committed to innovation and continuous development as a well-defined strategy to achieve excellence and creativity in all aspects of our work.",
        ar: "نلتزم بالابتكار والتطوير المستمر كاستراتيجية محكمة لتحقيق التميز و الابداع في جميع جوانب عملنا.",
      },
    },
    {
      id: 2,
      icon: FaHandshake,
      title: {
        en: "Integrity",
        ar: "النزاهة",
      },
      text: {
        en: "Trust is one of the most crucial pillars of success in our field, which is why we conduct all our dealings with integrity and reliability, focusing on adhering to high ethical standards.",
        ar: "الثقة هي أحد أهم مقومات النجاح في مجالنا، لذا ندير جميع معاملاتنا بنزاهة وجدارة، مع التركيز علي الالتزام بالمعايير الأخلاقية الرفيعة.",
      },
    },
    {
      id: 3,
      icon: GiThreeFriends,
      title: {
        en: "Credibility",
        ar: "المصداقية",
      },
      text: {
        en: "We are dedicated to fulfilling our commitments and executing all agreed-upon aspects with our clients, emphasizing our respect for obligations and responsibilities.",
        ar: "نلتزم بتحقيق التعهدات وتنفيذ جميع الجوانب المتفق عليها مع عملائنا، مؤكدين علي احترامنا للالتزامات و المسؤوليات.",
      },
    },
  ];

  return (
    <article className="row">
      {iconbox.map((data) => (
        <article className="col-md-4" key={data.id}>
          <article
            className="themesflat-spacer clearfix"
            data-desktop={0}
            data-mobile={0}
            data-smobile={35}
          />
          <article
            className="themesflat-content-box clearfix"
            data-margin="0 5px 0 5px"
            data-mobilemargin="0 0 0 0"
          >
            <article className="themesflat-icon-box icon-top align-center has-width w95 circle light-bg accent-color style-1 clearfix">
              <article className="icon-wrap">
                {React.createElement(data.icon, {
                  style: { fontSize: '4rem' }
                })}
              </article>
              <article className="text-wrap">
                <h5 className="heading text-center" style={{
                    color:show ? "" : "#fff",
                    marginTop: "6px"
                  }}>
                  <Link to="#">{data.title[currentLang]}</Link>
                </h5>
                <article className="sep clearfix " />
                <p
                  className="sub-headingfw-bold fs-4 text-center"
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 600,
                    color:show ? "" : "#fff"
                  }}
                >
                  {data.text[currentLang]}
                </p>
              </article>
            </article>
          </article>
        </article>
      ))}
    </article>
  );
};

export default IconBox;
