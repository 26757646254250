import React from "react";
import { useTranslation } from "react-i18next";

const TitleHeading = ({ data, show }) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  return (
    <article className={data.classnames}>
      <h2
        className="heading text-center"
        style={{ color: "#dda939", fontSize: "4rem", fontWeight: 600 }}
      >
        {typeof data.title === "object" ? data.title[currentLang] : data.title}
      </h2>
      <article
        className="text-center"
        style={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          color: show ? "" : "#fff",
          marginBottom: "1rem",
        }}
      >
        {typeof data.text === "object"
          ? Array.isArray(data.text)
            ? data.text.map((item) => item[currentLang])
            : data.text[currentLang]
          : data.text}
      </article>
      <article
        className="text-center"
        style={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          color: show ? "" : "#fff",
        }}
      >
        {typeof data.textSub === "object"
          ? Array.isArray(data.textSub)
            ? data.textSub.map((item) => item[currentLang])
            : data.textSub[currentLang]
          : data.textSub}
      </article>
    </article>
  );
};

export default TitleHeading;
