import React from "react";
import { Header, Footer, TopBar, BottomBar } from "../layouts/general";
import { useTranslation } from "react-i18next";
import TabProject from "../layouts/project/TabProject";

const Projects = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  

  const headerData = {
    id: 1,
    logoweb: "assets/img/logo-small.png",
    names: currentLang === "en" ? "Projects" : "المشاريع",
  };



  return (
    <article className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
      <article id="wrapper" className="animsition">
        <article id="page" className="clearfix">
          <article id="site-header-wrap"> 
            <TopBar />
            <Header data={headerData} currentLang={currentLang} />
          </article>
          <article
            id="main-content"
            className="site-main clearfix"
            style={{
              backgroundImage: `url(assets/img/parallax/parallax-2.webp)`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            <article id="content-wrap">
              <article id="site-content" className="site-content clearfix">
                <article id="inner-content" className="inner-content-wrap">
                  <article className="page-content">
                    <article className="row-services">
                      <article className="container">
                        <article className="row">
                          <article className="col-md-12">
                            <article
                              className="themesflat-spacer clearfix"
                              data-desktop={60}
                              data-mobile={60}
                              data-smobile={60}
                            />
                            <article
                              className="themesflat-spacer clearfix"
                              data-desktop={40}
                              data-mobile={35}
                              data-smobile={35}
                            />
                            <TabProject currentLang={currentLang}  />
                            <article
                              className="themesflat-spacer clearfix"
                              data-desktop={72}
                              data-mobile={60}
                              data-smobile={60}
                            />
                          </article>
                        </article>
                      </article>
                    </article>
                  </article>
                </article>
              </article>
            </article>
          </article>
          <Footer currentLang={currentLang} />
          <BottomBar data={headerData} currentLang={currentLang} />
        </article>
      </article>
    </article>
  );
};

export default Projects;