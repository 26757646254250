import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const serviceData = [
  {
    id: 1,
    srcimg: "assets/img/services/1.jpg",
    heading: {
      en: "Design and Planning",
      ar: "التصميم والتخطيط",
    },
    description: {
      en: "Preparing engineering and architectural plans, obtaining necessary permits from relevant authorities.",
      ar: "إعداد المخططات الهندسية والمعمارية، تقديم التراخيص اللازمة من الجهات المختصة.",
    },
  },
  {
    id: 2,
    srcimg: "assets/img/services/2.jpg",
    heading: {
      en: "Excavation and Foundations",
      ar: "أعمال الحفر والأساسات",
    },
    description: {
      en: "Site preparation for excavation, implementation of foundations and concrete bases.",
      ar: "تجهيز الموقع للحفر، تنفيذ الأساسات والقواعد الخرسانية.",
    },
  },
  {
    id: 3,
    srcimg: "assets/img/services/3.jpg",
    heading: {
      en: "Structural Work",
      ar: "الهيكل الإنشائي (العظم)",
    },
    description: {
      en: "Construction of walls, ceilings, columns, and reinforced concrete implementation.",
      ar: "بناء الجدران والأسقف والأعمدة، تنفيذ الخرسانة المسلحة.",
    },
  },
  {
    id: 4,
    srcimg: "assets/img/services/4.jpg",
    heading: {
      en: "Electrical and Mechanical Works",
      ar: "الأعمال الكهربائية والميكانيكية",
    },
    description: {
      en: "Installation of electrical and water networks, AC systems, ventilation, and sanitary drainage.",
      ar: "تمديد شبكات الكهرباء والمياه، تركيب أنظمة التكييف، التهوية، والصرف الصحي.",
    },
  },
  {
    id: 5,
    srcimg: "assets/img/services/5.jpg",
    heading: {
      en: "Interior Finishing",
      ar: "التشطيبات الداخلية",
    },
    description: {
      en: "Wall painting, flooring installation, windows, doors, and kitchen installation.",
      ar: "دهانات الجدران وتركيب الأرضيات، تركيب النوافذ، الأبواب، والمطابخ.",
    },
  },
  {
    id: 6,
    srcimg: "assets/img/services/6.jpg",
    heading: {
      en: "Exterior Coordination",
      ar: "التنسيق الخارجي",
    },
    description: {
      en: "Landscaping, exterior spaces, parking lots, and outdoor lighting implementation.",
      ar: "تنسيق الحدائق والمساحات الخارجية، تنفيذ مواقف السيارات والإنارة الخارجية.",
    },
  },
  {
    id: 7,
    srcimg: "assets/img/services/7.jpg",
    heading: {
      en: "Turnkey Delivery",
      ar: "تسليم مفتاح",
    },
    description: {
      en: "Project delivery ready for use",
      ar: "تسليم المشروع جاهزًا",
    },
  },
];

const ServiceSlide = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  return (
		<article
			className="row-services"
			style={{
				backgroundImage: `url(assets/img/parallax/parallax-2.webp)`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundPosition: "center center",
			}}
		>
			<article className="container">
				<article className="row">
					<article className="col-md-12 responsive">
						<article
							className="themesflat-spacer clearfix"
							data-desktop={81}
							data-mobile={60}
							data-smobile={60}
						/>
						<article
							className="themesflat-carousel-box data-effect clearfix"
							data-gap={30}
							data-column={3}
							data-column2={2}
							data-column3={1}
							data-auto="true"
							data-nav="true"
							data-dots="false"
							data-center="true"
							data-loop="true"
						>
							<article className="owl-carousel owl-theme">
								{serviceData.map((item) => (
									<article
										className="themesflat-image-box style-2 clearfix"
										key={item.id}
										style={{
											flex: "0 0 auto",
											width: "100%",
											maxWidth: "370px",
										}}
									>
										<article className="image-box-item">
											<article className="inner">
												<article className="thumb data-effect-item">
													<img
														loading="lazy"
														src={item.srcimg}
														alt="خدمات مقاولات متكاملة - مقاول تشطيب وترميم"
														className="service-image"
													/>
													<article className="overlay-effect bg-color-accent" />
												</article>
												<article
													className="text-wrap"
													style={{
														textAlign: currentLang === "ar" ? "right" : "left",
														direction: currentLang === "ar" ? "rtl" : "ltr",
													}}
												>
													<h5
														className="heading"
														style={{
															color: "white",
															textAlign:
																currentLang === "ar" ? "right" : "left",
														}}
													>
														<Link to="#">{item.heading[currentLang]}</Link>
													</h5>
													<p
														className="letter-spacing-01"
														style={{
															color: "white",
															textAlign:
																currentLang === "ar" ? "right" : "left",
														}}
													>
														{item.description[currentLang]}
													</p>
													<article
														className="elm-readmore"
														style={{
															textAlign:
																currentLang === "ar" ? "right" : "left",
														}}
													></article>
												</article>
											</article>
										</article>
									</article>
								))}
							</article>
						</article>
						<article
							className="themesflat-spacer clearfix"
							data-desktop={45}
							data-mobile={60}
							data-smobile={60}
						/>
					</article>
				</article>
			</article>
		</article>
	);
};

export default ServiceSlide;
