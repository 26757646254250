import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./i18n"; // استيراد ملف الترجمة
import "./index.css";

const savedLang = localStorage.getItem("language") || "ar";
document.documentElement.dir = savedLang === "ar" ? "rtl" : "ltr";
document.documentElement.lang = savedLang;

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById("root")
);
