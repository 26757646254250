import React from "react";
import { Link } from "react-router-dom";

const projectsData = [
  {
    id: 1,
    srcimg: "assets/img/project/011.png",
    heading: {
      en: "Al-Amal Hospital",
      ar: "مستشفى الأمل",
    },
    meta: {
      en: "Interior Design",
      ar: "تشطيبات داخلية",
    },
    text: {
      en: "Construction",
      ar: "إنشاءات",
    },
  },
  {
    id: 2,
    srcimg: "assets/img/project/08.png",
    heading: {
      en: "Sheikh Nayef Al-Qahtani Palace",
      ar: "قصر الشيخ نايف القحطاني",
    },
    meta: {
      en: "Architecture",
      ar: "عمارة",
    },
    text: {
      en: "Building",
      ar: "بناء",
    },
  },
  {
    id: 3,
    srcimg: "assets/img/project/07.png",
    heading: {
      en: "Prince Mohammed bin Sultan Palace",
      ar: "قصر الأمير محمد بن سلطان بن عبدالعزيز آل سعود",
    },
    meta: {
      en: "Supply & Installation",
      ar: "توريد و تركيب",
    },
    text: {
      en: "General Maintenance",
      ar: "صيانة عامة",
    },
  },
  {
    id: 4,
    srcimg: "assets/img/project/05.png",
    heading: {
      en: "Al-Jubair Kings Villa",
      ar: "فلة ملوك الجبير",
    },
    meta: {
      en: "Supply & Installation",
      ar: "توريد و تركيب",
    },
    text: {
      en: "Windows & Kitchen",
      ar: "نوافذ أبواب أستر تشر مطابخ",
    },
  },
  {
    id: 5,
    srcimg: "assets/img/project/3.png",
    heading: {
      en: "Dr. Fayez Azzam Villa",
      ar: "فلة الدكتور فايز عزام الرحيمين",
    },
    meta: {
      en: "Supply & Installation",
      ar: "توريد و تركيب",
    },
    text: {
      en: "Windows & Interior Design",
      ar: "نوافذ و أبواب كارت نول و تشطيبات داخلية",
    },
  },
];

const Project = ({ currentLang = "en" }) => {
  return (
    <article
      className="themesflat-carousel-box clearfix"
      data-gap={30}
      data-column={4}
      data-column2={2}
      data-column3={1}
      data-auto="false"
    >
      <article className="owl-carousel owl-theme">
        {projectsData.map((data) => (
          <article
            className="themesflat-project style-1 data-effect clearfix"
            key={data.id}
          >
            <article className="project-item">
              <article className="inner">
                <article className="thumb data-effect-item has-effect-icon w40 offset-v-43 offset-h-46">
                  <img
                    src={data.srcimg}
                    alt="مقاول تشطيب - مقاول ترميمات - افضل مقاول ترميم"
                    loading="lazy"
                  />
                  <article className="text-wrap text-center">
                    <h5 className="heading">
                      <Link to={`#`}>{data.heading[currentLang]}</Link>
                    </h5>
                  </article>
                  <article className="overlay-effect bg-color-3" />
                </article>
              </article>
            </article>
          </article>
        ))}
      </article>
    </article>
  );
};

export default Project;
