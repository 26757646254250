import React from "react";
import { Link } from "react-router-dom";

const recentPosts = [
  {
    id: 1,
    srcimg: "assets/img/news/post-1-65x65.webp",
    title: {
      en: "Smart Building Technologies in Saudi Arabia",
      ar: "تقنيات المباني الذكية في المملكة العربية السعودية",
    },
    date: {
      en: "29 June 2023",
      ar: "29 يونيو 2023",
    },
  },
  {
    id: 2,
    srcimg: "assets/img/news/post-2-65x65.webp",
    title: {
      en: "Sustainable Construction in the Kingdom",
      ar: "البناء المستدام في المملكة",
    },
    date: {
      en: "31 July 2023",
      ar: "31 يوليو 2023",
    },
  },
  {
    id: 3,
    srcimg: "assets/img/news/post-3-65x65.jpg",
    title: {
      en: "Modern Architecture in Saudi Projects",
      ar: "العمارة الحديثة في المشاريع السعودية",
    },
    date: {
      en: "15 August 2023",
      ar: "15 أغسطس 2023",
    },
  },
];

const tags = [
  { id: 1, en: "Building", ar: "البناء" },
  { id: 2, en: "Smart House", ar: "المنزل الذكي" },
  { id: 3, en: "Construction", ar: "التشييد" },
  { id: 4, en: "Villa", ar: "فيلا" },
  { id: 5, en: "Residential", ar: "سكني" },
  { id: 6, en: "Interior", ar: "تصميم داخلي" },
  { id: 7, en: "Resort", ar: "منتجع" },
  { id: 8, en: "Commercial", ar: "تجاري" },
];

const socialStats = [
  {
    id: 1,
    platform: "facebook",
    icon: "fa fa-facebook",
    stats: {
      en: "43,217 likes",
      ar: "43,217 إعجاب",
    },
  },
  {
    id: 2,
    platform: "twitter",
    icon: "fa fa-twitter",
    stats: {
      en: "3,752 followers",
      ar: "3,752 متابع",
    },
  },
  {
    id: 3,
    platform: "google",
    icon: "fa fa-google",
    stats: {
      en: "432 contacts",
      ar: "432 جهة اتصال",
    },
  },
];

const SidebarBlog = ({ currentLang = "en" }) => {
  return (
		<article id="sidebar">
			<article
				className="themesflat-spacer clearfix"
				data-desktop={0}
				data-mobile={60}
				data-smobile={60}
			/>
			<article id="inner-sidebar" className="inner-content-wrap">
				<article className="widget widget_search">
					<form
						action="#"
						method="get"
						role="search"
						className="search-form style-1"
					>
						<input
							type="search"
							className="search-field"
							placeholder={currentLang === "en" ? "Search..." : "بحث..."}
							name="s"
							title="Search for"
						/>
						<button className="search-submit" type="submit" title="Search">
							{currentLang === "en" ? "Search" : "بحث"}
						</button>
					</form>
				</article>

				<article className="widget widget_follow">
					<h2 className="widget-title">
						<span>{currentLang === "en" ? "FOLLOW US" : "تابعنا"}</span>
					</h2>
					<article className="follow-wrap clearfix col3 g8">
						{socialStats.map((social) => (
							<article className={`follow-item ${social.platform}`} key={social.id}>
								<article className="inner">
									<span className="socials">
										<Link to="#">
											<i className={social.icon} />
										</Link>
										<span className="text">{social.stats[currentLang]}</span>
									</span>
								</article>
							</article>
						))}
					</article>
				</article>

				<article className="widget widget_lastest">
					<h2 className="widget-title">
						<span>
							{currentLang === "en" ? "RECENT POSTS" : "آخر المنشورات"}
						</span>
					</h2>
					<ul className="lastest-posts data-effect clearfix">
						{recentPosts.map((post) => (
							<li className="clearfix" key={post.id}>
								<article className="thumb data-effect-item has-effect-icon">
									<img
										src={post.srcimg}
										alt="مقاول هدم وتكسير - مقاول ترميمات - مقاولات عامة بالرياض"
										loading="lazy"
									/>
									<article className="overlay-effect bg-color-2" />
									<article className="elm-link">
										<Link to="/blog-single" className="icon-2" />
									</article>
								</article>
								<article className="text">
									<h3>
										<Link to="/blog-single">{post.title[currentLang]}</Link>
									</h3>
									<span className="post-date">
										<span className="entry-date">{post.date[currentLang]}</span>
									</span>
								</article>
							</li>
						))}
					</ul>
				</article>

				<article className="widget widget_tags margin-top-55">
					<h2 className="widget-title">
						<span>{currentLang === "en" ? "TAGS" : "الوسوم"}</span>
					</h2>
					<article className="tags-list">
						{tags.map((tag) => (
							<Link to="#" key={tag.id}>
								{tag[currentLang]}
							</Link>
						))}
					</article>
				</article>
			</article>
		</article>
	);
};

export default SidebarBlog;
