import React from "react";
import { Link } from "react-router-dom";
import blogData from "../../../Data/blog";
import { FiEdit3 } from "react-icons/fi";

const ContentBlog = ({ currentLang = "en" }) => {
	return (
		<article id="site-content" className="site-content clearfix">
			<article
				id="inner-content"
				className="inner-content-wrap"
				style={{
					direction: currentLang === "ar" ? "rtl" : "ltr",
				}}
			>
				{blogData.map((data) => (
					<article className="hentry data-effect" key={data.id}>
						<article className="post-media has-effect-icon offset-v-25 offset-h-24 data-effect-item clerafix">
							<Link to="/blog-single">
								{" "}
								<img
									src={data.srcimg}
									alt="مقاول عام الرياض - مقاولات عامة بالرياض"
									loading="lazy"
								/>
							</Link>

							<article
								className="post-calendar"
								style={{
									right: currentLang === "ar" ? "24px" : "auto",
									left: currentLang === "ar" ? "auto" : "24px",
								}}
							>
								<span className="inner">
									<span className="entry-calendar">
										<span className="day">{data.day}</span>
										<span className="month">{data.month[currentLang]}</span>
									</span>
								</span>
							</article>
							<article className="overlay-effect bg-color-1" />
						</article>
						<article
							className="post-content-wrap clearfix"
							style={{
								textAlign: currentLang === "ar" ? "right" : "left",
							}}
						>
							<h2
								className="post-title"
								style={{
									textAlign: currentLang === "ar" ? "right" : "left",
								}}
							>
								<span className="post-title-inner">
									{data.title[currentLang]}
								</span>
							</h2>
							<article
								className="post-meta"
								style={{
									textAlign: currentLang === "ar" ? "right" : "left",
									marginBottom: "20px",
									color: "#777",
									fontSize: "14px",
									display: "flex",
									alignItems: "center",
									justifyContent:
										currentLang === "ar" ? "flex-start" : "flex-end",
									gap: "5px",
									flexDirection: currentLang === "ar" ? "row" : "row-reverse",
								}}
							>
								<FiEdit3 style={{ marginTop: "2px" }} />
								{currentLang === "ar"
									? "كتابة المقال بواسطة شركة إيليت للمقاولات"
									: "Article by Elite Contracting Company"}
							</article>
							<article
								className="post-content post-excerpt"
								style={{
									textAlign: currentLang === "ar" ? "right" : "left",
								}}
							>
								<p>{data.descriptions[currentLang]}</p>
							</article>
						</article>
					</article>
				))}
			</article>
		</article>
	);
};

export default ContentBlog;
