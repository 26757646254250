import React, { useEffect } from "react";

import "./ProjectPopup.css";

const ProjectPopup = ({ project, onClose, currentLang }) => {
  useEffect(() => {
    if (project) {
      document.body.classList.add('popup-open');
      
      return () => {
        document.body.classList.remove('popup-open');
      };
    }
  }, [project]);

  if (!project) return null;

  return (
		<article className="project-popup-overlay" onClick={onClose}>
			<article
				className="project-popup-content"
				onClick={(e) => e.stopPropagation()}
			>
				<button className="close-button" onClick={onClose}>
					×
				</button>
				<h3 className="popup-title">{project.heading[currentLang]}</h3>
				<article className="project-images-grid">
					{/* Main project image */}
					<article className="project-image-item">
						<article className="inner">
							<article className="thumb data-effect-item">
								<img
									loading="lazy"
									src={project.srcimg}
									alt="مقاول تشطيب وترميم - مقاول بناء ملاحق - مقاولات عامة"
								/>
								<article className="overlay-effect bg-color-3" />
							</article>
						</article>
					</article>
					{/* Additional project images from children */}
					{project.children?.map((child) => (
						<article className="project-image-item" key={child.id}>
							<article className="inner">
								<article className="thumb data-effect-item">
									<img
										loading="lazy"
										src={child.srcimg}
										alt="مقاول هدم وبناء - مقاول ترميمات - افضل مقاول بالرياض"
									/>
									<article className="overlay-effect bg-color-3" />
								</article>
							</article>
						</article>
					))}
				</article>
			</article>
		</article>
	);
};

export default ProjectPopup;