import React, { useState } from "react";

const FormContact = ({ currentLang = "en" }) => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Format the message for WhatsApp
    const whatsappMessage = `*New Contact Form Submission*%0A
*Name:* ${formData.name}%0A
*Subject:* ${formData.subject}%0A
*Email:* ${formData.email}%0A
*Phone:* ${formData.phone}%0A
*Message:* ${formData.message}`;

    // WhatsApp number
    const whatsappNumber = "966509553440"; // Removed spaces and + for URL compatibility
    
    // Create WhatsApp URL
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${whatsappMessage}`;
    
    // Redirect to WhatsApp
    window.open(whatsappUrl, '_blank');
  };

  const translations = {
    title: {
      en: "CONTACT US",
      ar: "اتصل بنا",
    },
    description: {
      en: "Are you interested in finding out how Elite Construction Services can make your project a success? For more information on our services please contact us.",
      ar: "هل تريد معرفة كيف يمكن لخدمات ايليت للإنشاءات أن تجعل مشروعك ناجحاً؟ لمزيد من المعلومات حول خدماتنا، يرجى الاتصال بنا.",
    },
    form: {
      name: {
        placeholder: {
          en: "Name*",
          ar: "الاسم*",
        },
      },
      phone: {
        placeholder: {
          en: "Phone",
          ar: "رقم الهاتف",
        },
      },
      email: {
        placeholder: {
          en: "Email*",
          ar: "البريد الإلكتروني*",
        },
      },
      subject: {
        placeholder: {
          en: "Subject",
          ar: "الموضوع",
        },
      },
      message: {
        placeholder: {
          en: "Message*",
          ar: "الرسالة*",
        },
      },
      submit: {
        text: {
          en: "SEND MESSAGE",
          ar: "إرسال الرسالة",
        },
      },
    },
  };

  const formStyle = {
    textAlign: currentLang === "ar" ? "right" : "left",
    direction: currentLang === "ar" ? "rtl" : "ltr"
  };

  return (
    <article className="col-md-6" style={formStyle}>
      <article className="themesflat-headings style-2 clearfix">
        <h2 className="heading">{translations.title[currentLang]}</h2>
        <article className="sep has-width w80 accent-bg clearfix" />
        <p className="sub-heading">{translations.description[currentLang]}</p>
      </article>
      <article
        className="themesflat-spacer clearfix"
        data-desktop={36}
        data-mobile={35}
        data-smobile={35}
      />
      <article className="themesflat-contact-form style-2 clearfix">
        <form
          method="post"
          className="form-submit contact-form wpcf7-form"
          onSubmit={handleSubmit}
        >
          <span className="wpcf7-form-control-wrap your-name">
            <input
              type="text"
              tabIndex={1}
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="wpcf7-form-control"
              placeholder={translations.form.name.placeholder[currentLang]}
              required
              dir={currentLang === "ar" ? "rtl" : "ltr"}
            />
          </span>
          <span className="wpcf7-form-control-wrap your-phone">
            <input
              type="text"
              tabIndex={2}
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="wpcf7-form-control"
              placeholder={translations.form.phone.placeholder[currentLang]}
              dir={currentLang === "ar" ? "rtl" : "ltr"}
            />
          </span>
          <span className="wpcf7-form-control-wrap your-email">
            <input
              type="email"
              tabIndex={3}
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="wpcf7-form-control"
              placeholder={translations.form.email.placeholder[currentLang]}
              required
              dir={currentLang === "ar" ? "rtl" : "ltr"}
            />
          </span>
          <span className="wpcf7-form-control-wrap your-subject">
            <input
              type="text"
              tabIndex={4}
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="wpcf7-form-control"
              placeholder={translations.form.subject.placeholder[currentLang]}
              dir={currentLang === "ar" ? "rtl" : "ltr"}
            />
          </span>
          <span className="wpcf7-form-control-wrap your-message">
            <textarea
              tabIndex={5}
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="wpcf7-form-control wpcf7-textarea"
              placeholder={translations.form.message.placeholder[currentLang]}
              required
              dir={currentLang === "ar" ? "rtl" : "ltr"}
            />
          </span>
          <span className="wrap-submit">
            <input
              type="submit"
              value={translations.form.submit.text[currentLang]}
              className="submit wpcf7-form-control wpcf7-submit"
              id="submit"
              name="submit"
            />
          </span>
        </form>
      </article>
      <article
        className="themesflat-spacer clearfix"
        data-desktop={80}
        data-mobile={60}
        data-smobile={60}
      />
    </article>
  );
};

export default FormContact;
