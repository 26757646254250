const bannerhome1 = [
  {
    id: 1,
    srcimg2: "assets/img/slider/slider-bg-1copy.webp",
    srcimg: "assets/img/slider/slider-bg-5.png",
    classnames:
      "tp-caption tp-resizeme text-white font-heading font-weight-900",
    classtext: "tp-caption tp-resizeme text-white font-heading font-weight-700",
    datahset: "34",
    datavset1: "-134",
    datavset2: "-63",
    datavset3: "2",
    datavset: "106",
    classbtn: "tp-caption",
    title: {
      en: "Who We Are",
      ar: "مــن نحــن",
    },
    description1: {
      en: "Elite Contracting Company is ",
      ar: "مؤسسة إيليت للمقاولات هي مؤسسة رائدة في",
    },
    description2: {
      en: "in the field of general contracting .",
      ar: "مجال المقاولات العامة وتقديم الحلول الهندسية المبتكرة",
    },
    buttonText: {
      en: "GET IN TOUCH",
      ar: "تواصل معنا",
    },
  },
  {
    id: 3,
    srcimg2: "assets/img/slider/slider-bg-2copy.webp",
    srcimg: "assets/img/slider/slider-bg-6.png",
    classnames:
      "tp-caption tp-resizeme text-white font-heading font-weight-900",
    classtext: "tp-caption tp-resizeme text-white font-heading font-weight-700",
    datahset: "34",
    datavset1: "-134",
    datavset2: "-63",
    datavset3: "2",
    datavset: "106",
    classbtn: "tp-caption",
    title: {
      en: "Our Mission",
      ar: "مهمتنا",
    },
    description1: {
      en: "We strive to be your trusted partner",
      ar: "نسعى لنكون شريككم الموثوق",
    },
    description2: {
      en: "in executing construction projects of all sizes and types.",
      ar: "في تنفيذ المشاريع الإنشائية بكافة أحجامها وأنواعها",
    },
    buttonText: {
      en: "GET IN TOUCH",
      ar: "تواصل معنا",
    },
  },
];

export default bannerhome1;
