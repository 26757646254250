import React, { useState, useMemo, useCallback, useEffect } from "react";
import { projectData } from "../../../Data/projectData";
import ProjectPopup from "./ProjectPopup";
import "./TabProject.css";

const ITEMS_PER_PAGE = 6;

const ProjectCard = React.memo(({ data, currentLang, onClick }) => {
  return (
    <article className="project-card" onClick={onClick}>
      <article className="project-inner">
        <article className="project-thumb">
          <img  
            src={data.srcimg} 
            alt={data.heading[currentLang]}
            loading="lazy"
          />
          <article className="overlay-effect" />
        </article>
        <article className="project-info">
          <h3 className="project-title" style={{
            color:"#dda939",
            textAlign: currentLang === "ar" ? "right" : "left"
          }}>
            {data.heading[currentLang]}
          </h3>
          <span className="project-category" style={{
            textAlign: currentLang === "ar" ? "right" : "left",
            width: "100%",
            display: "block"
          }}>
            {data.type[currentLang]}
          </span>
        </article>
      </article>
    </article>
  );
});

const ProjectGrid = React.memo(({ projects, currentLang, onProjectClick }) => {
  return (
    <article className="projects-grid">
      {projects.map((project) => (
        <ProjectCard
          key={project.id}
          data={project}
          currentLang={currentLang}
          onClick={() => onProjectClick(project)}
        />
      ))}
    </article>
  );
});

const TabProject = ({ currentLang = "en" }) => {
  // State management
  const [visibleCount, setVisibleCount] = useState(ITEMS_PER_PAGE);
  const [selectedType, setSelectedType] = useState("*");
  const [selectedProject, setSelectedProject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Get unique project types
  const projectTypes = useMemo(() => {
    const types = new Set(projectData.map(project => project.type[currentLang]));
    return [
      { id: "*", label: currentLang === "en" ? "All" : "الكل" },
      ...Array.from(types).map(type => ({
        id: type.toLowerCase(),
        label: type
      }))
    ];
  }, [currentLang]);

  // Filter projects based on selected type
  const filteredProjects = useMemo(() => {
    if (selectedType === "*") return projectData;
    return projectData.filter(project => 
      project.type[currentLang].toLowerCase() === selectedType
    );
  }, [selectedType, currentLang]);

  // Get currently visible projects
  const visibleProjects = useMemo(() => {
    return filteredProjects.slice(0, visibleCount);
  }, [filteredProjects, visibleCount]);

  // Handle type selection
  const handleTypeSelect = useCallback((typeId) => {
    setSelectedType(typeId);
    setVisibleCount(ITEMS_PER_PAGE);
  }, []);

  // Handle project click
  const handleProjectClick = useCallback((project) => {
    if (project?.children?.length > 0) {
      setSelectedProject(project);
    }
  }, []);

  // Handle load more
  const handleLoadMore = useCallback(() => {
    setIsLoading(true);
    // Simulate loading for smooth UX
    setTimeout(() => {
      setVisibleCount(prev => Math.min(prev + ITEMS_PER_PAGE, filteredProjects.length));
      setIsLoading(false);
    }, 300);
  }, [filteredProjects.length]);

  // Handle show less
  const handleShowLess = useCallback(() => {
    setVisibleCount(ITEMS_PER_PAGE);
  }, []);

  // Reset visible count when type changes
  useEffect(() => {
    setVisibleCount(ITEMS_PER_PAGE);
  }, [selectedType]);

  return (
    <article className="project-showcase">
      {/* Project Types Filter */}
      <article className="project-types" style={{
        display: 'flex',
        flexDirection: currentLang === "ar" ? "row-reverse" : "row",
        justifyContent: currentLang === "ar" ? "flex-start" : "flex-end ",
        gap: "1rem",
        width: "100%",
        padding: "0 2rem"
      }}>
        {projectTypes.map(type => (
          <button
            key={type.id}
            className={`type-button ${selectedType === type.id ? "active" : ""}`}
            onClick={() => handleTypeSelect(type.id)}
          >
            {type.label}
          </button>
        ))}
      </article>

      {/* Projects Grid */}
      <ProjectGrid
        projects={visibleProjects}
        currentLang={currentLang}
        onProjectClick={handleProjectClick}
      />

      {/* Load More/Less Controls */}
      <article className="load-controls">
        {visibleCount < filteredProjects.length && (
          <button
            className="load-button"
            onClick={handleLoadMore}
            disabled={isLoading}
          >
            {isLoading 
              ? (currentLang === "en" ? "Loading..." : "جاري التحميل...")
              : (currentLang === "en" ? "Load More" : "عرض المزيد")
            }
          </button>
        )}
        {visibleCount > ITEMS_PER_PAGE && (
          <button
            className="load-button"
            onClick={handleShowLess}
          >
            {currentLang === "en" ? "Show Less" : "عرض أقل"}
          </button>
        )}
      </article>

      {/* Project Popup */}
      {selectedProject && (
        <ProjectPopup
          project={selectedProject}
          onClose={() => setSelectedProject(null)}
          currentLang={currentLang}
        />
      )}
    </article>
  );
};

export default TabProject;