import React from "react";
import { Header, Footer, TopBar, BottomBar } from "../layouts/general/index";
import { useTranslation } from "react-i18next";
import { ServiceSlide } from "../layouts/services";

const Services = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const headerData = {
    id: 1,
    logoweb: "assets/img/logo-small.png",
    names: currentLang === "en" ? "Services" : "خدماتنا",
  };

  return (
    <article className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
      <article id="wrapper" className="animsition">
        <article id="page" className="clearfix">
          <article id="site-header-wrap">
            <TopBar />
            <Header data={headerData} currentLang={currentLang} />
          </article>
          <article id="main-content" className="site-main clearfix">
            <article id="content-wrap">
              <article id="site-content" className="site-content clearfix">
                <article id="inner-content" className="inner-content-wrap">
                  <article className="page-content">
                    <ServiceSlide currentLang={currentLang} />
                  </article>
                </article>
              </article>
            </article>
          </article>
          <Footer currentLang={currentLang} />
          <BottomBar currentLang={currentLang} />
        </article>
      </article>
    </article>
  );
};

export default Services;
