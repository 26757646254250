import React from 'react';
import { useTranslation } from 'react-i18next';

const counterData = [
    {
        id: 1,
        number: 650,
        title: {
            en: "PROJECTS COMPLETED",
            ar: "مشروع مكتمل"
        }
    },
    {
        id: 2,
        number: 17,
        title: {
            en: "YEARS OF EXPERIENCE",
            ar: "سنوات من الخبرة"
        }
    },
    {
        id: 3,
        number: 650,
        title: {
            en: "SATISFIED CLIENTS",
            ar: "عميل راضٍ"
        }
    },
    {
        id: 4,
        number: 1200,
        title: {
            en: "WORKERS EMPLOYED",
            ar: "موظف وعامل"
        }
    }
];

const Counter = () => {
    const { i18n } = useTranslation();
    const currentLang = i18n.language;

    return (
        <article className="row-counter parallax parallax-4 parallax-overlay">
            <article className="container">
                <article className="row clearfix">
                    <article className="col-md-12">
                        <article className="themesflat-spacer clearfix" 
                            data-desktop={92} 
                            data-mobile={60} 
                            data-smobile={60} 
                        />
                    </article>
                </article>
                <article className="themesflat-row gutter-30 separator light clearfix">
                    {counterData.map((item) => (
                        <article className="col span_1_of_3" key={item.id}>
                            <article className="themesflat-content-box clearfix" 
                                data-margin="0 0 0 0" 
                                data-mobilemargin="0 0 0 0"
                            >
                                <article className="themesflat-counter style-1 align-center clearfix">
                                    <article className="counter-item">
                                        <article className="inner">
                                            <article className="text-wrap">
                                                <article className="number-wrap">
                                                    <span 
                                                        className="number" 
                                                        data-speed={2000} 
                                                        data-to={item.number}
                                                        data-inviewport="yes"
                                                    >
                                                        {item.number}
                                                    </span>
                                                    <span className="suffix">+</span>
                                                </article>
                                                <h3 className="heading margin-right-18">
                                                    {item.title[currentLang]}
                                                </h3>
                                            </article>
                                        </article>
                                    </article>
                                </article>
                            </article>
                            <article className="themesflat-spacer clearfix" 
                                data-desktop={0} 
                                data-mobile={35} 
                                data-smobile={35} 
                            />
                        </article>
                    ))}
                </article>
                <article className="row clearfix">
                    <article className="col-md-12">
                        <article className="themesflat-spacer clearfix" 
                            data-desktop={72} 
                            data-mobile={60} 
                            data-smobile={60} 
                        />
                    </article>
                </article>
            </article>
            <article className="bg-parallax-overlay overlay-black style2" />
        </article>
    );
};

export default Counter;