import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import menus from "../menu";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

const Header = ({ data }) => {
	const [currentLang, setCurrentLang] = useState(
		localStorage.getItem("language") || "ar"
	);
	const { t } = useTranslation();

	useEffect(() => {
		const savedLang = localStorage.getItem("language") || "ar";
		document.documentElement.dir = savedLang === "ar" ? "rtl" : "ltr";
		i18n.changeLanguage(savedLang);
	}, []);

	const toggleLanguage = async (e) => {
		try {
			e.preventDefault();
			const newLang = currentLang === "ar" ? "en" : "ar";

			await i18n.changeLanguage(newLang);

			localStorage.setItem("language", newLang);

			document.documentElement.dir = newLang === "ar" ? "rtl" : "ltr";
			document.documentElement.lang = newLang;

			setCurrentLang(newLang);

			window.location.reload();
		} catch (error) {
			console.error("Error changing language:", error);
		}
	};

	return (
		<header
			id="site-header"
			className="headerNav"
			style={{ height: "70px", zIndex: "999" }}
		>
			<article
				id="site-header-inner"
				className="container h-100"
				style={{ display: "flex", alignItems: "center" }}
			>
				<article
					className={`wrap-inner clearfix ${
						currentLang === "ar" ? "rtl-layout" : "ltr-layout"
					}`}
				>
					{/* Logo */}
					<article id="site-logo" className="clearfix">
						<article id="site-log-inner">
							<Link to="/" className="main-logo">
								<img
									loading="lazy"
									src="assets/elet-logo3.png"
									alt="elite"
									style={{ width: "145px" }}
									width={60}
									height={30}
									data-retina="assets/elet-logo3.png"
									data-width={146}
									data-height={30}
								/>
							</Link>
						</article>
					</article>

					{/* Mobile Button */}
					<article
						className={`mobile-button ${
							currentLang === "ar" ? "mobile-button-rtl" : "mobile-button-ltr"
						}`}
					>
						<span />
					</article>

					{/* Navigation */}
					<nav
						id="main-nav"
						className={`main-nav ${
							currentLang === "ar" ? "nav-ar-desktop" : ""
						}`}
					>
						<ul id="menu-primary-menu" className="menu">
							{menus.map((menuItem) => (
								<li
									className={
										menuItem.hasDropdown
											? `menu-item menu-item-has-children ${
													menuItem.name === data.names
														? "current-menu-item"
														: ""
											  }`
											: `menu-item ${
													menuItem.name === data.names
														? "current-menu-item"
														: ""
											  }`
									}
									key={menuItem.id}
								>
									{menuItem.hasDropdown ? (
										<>
											<NavLink
												to=""
												style={({ isActive }) => ({
													...(isActive
														? {
																backgroundColor: "#dda939",
																borderRadius: "4px",
																color: "white",
																height: "40px",
																display: "flex",
																alignItems: "center",
																justifyContent: "center",
																padding: "8px 15px",
														  }
														: {}),
												})}
											>
												{t(menuItem.name)}
											</NavLink>
											<ul className="sub-menu">
												{menuItem.namesub.map((submenu) => (
													<li
														className="menu-item"
														style={{ textAlign: "center" }}
														key={submenu.id}
													>
														<NavLink
															to={submenu.links}
															onClick={() => {
																window.location.hash = submenu.links;
																window.location.reload();
															}}
															style={({ isActive }) => ({
																...(isActive
																	? {
																			backgroundColor: "#dda939",
																			borderRadius: "4px",
																			color: "white",
																			height: "40px",
																			display: "flex",
																			alignItems: "center",
																			justifyContent: "center",
																			padding: "8px 15px",
																	  }
																	: {}),
															})}
														>
															{t(submenu.sub)}
														</NavLink>
													</li>
												))}
											</ul>
										</>
									) : (
										<NavLink
											to={menuItem.link}
											onClick={() => {
												window.location.hash = menuItem.link;
												window.location.reload();
											}}
											style={({ isActive }) => ({
												...(isActive
													? {
															backgroundColor: "#dda939",
															borderRadius: "4px",
															color: "white",
															height: "40px",
															display: "flex",
															alignItems: "center",
															justifyContent: "center",
															padding: "8px 15px",
													  }
													: {}),
											})}
										>
											{t(menuItem.name)}
										</NavLink>
									)}
								</li>
							))}
							<li
								className="menu-item"
								style={{ display: "flex", alignItems: "center" }}
							>
								{/* Language Switcher */}
								<button
									onClick={toggleLanguage}
									className="lang-button"
									style={{
										border: "none",
										background: "#dda939",
										cursor: "pointer",
										padding: "8px 15px",
										borderRadius: "4px",
										color: "white",
										display: "flex",
										alignItems: "center",
										height: "35px",
										lineHeight: "1",
									}}
								>
									{currentLang === "ar" ? "EN" : "عربي"}
								</button>
							</li>
						</ul>
					</nav>
				</article>
			</article>
		</header>
	);
};

export default Header;
