import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Counter } from "../layouts/about";
import {
  TitleHeading,
  IconBox,
  About,
  Overview,
  Faq,
  Partner,
} from "../layouts/home01/index";
import { Header, Footer, TopBar, BottomBar } from "../layouts/general/index";

const pageData = {
  headers: [
    {
      id: 1,
      logoweb: "assets/img/logo-small.png",
      names: {
        en: "About Us",
        ar: "من نحن",
      },
    },
  ],
  headingchoose: [
    {
      id: 1,
      classnames: "heading",
      title: {
        en: "WHY US",
        ar: "لماذا نحن",
      },
      text: [
        {
          en: "With over 17 years of experience in construction excellence.",
          ar: "خبرة تزيد عن 17 عامًا في التميز في مجال البناء.",
        },
      ],
      textSub:[
        {
          en: "We have successfully completed numerous projects across Saudi Arabia, establishing ourselves as a trusted industry leader.",
          ar: "أنجزنا بنجاح العديد من المشاريع في جميع أنحاء المملكة العربية السعودية، مما جعلنا روادًا موثوقين في هذا المجال.",
        }
      ],
      classtext: "sub-heading text-center",
    },
  ],
  executeProject: {
    en: "START EXECUTING YOUR PROJECT",
    ar: "ابدأ تنفيذ مشروعك",
  },
  aboutButton: {
    en: "ABOUT US",
    ar: "من نحن",
  },
};

const AboutUs = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  return (
    <article className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
      <article id="wrapper" className="animsition">
        <article id="page" className="clearfix">
          <article id="site-header-wrap">
            <TopBar />
            {pageData.headers.map((data, idx) => (
              <Header
                data={{ ...data, names: data.names[currentLang] }}
                key={idx}
              />
            ))}
          </article>
          <article id="main-content" className="site-main clearfix">
            {/* ... existing structure ... */}
            <article
              className="row-iconbox"
              style={{
                backgroundImage: `url(assets/img/parallax/parallax-2.webp)`,
                
              }}
            >
              <article className="container">
                <article className="row">
                  <article className="col-md-12">
                    <article
                      className="themesflat-spacer clearfix"
                      data-desktop={60}
                      data-mobile={60}
                      data-smobile={60}
                    />
                    {pageData.headingchoose.map((data) => (
                      <TitleHeading
                        data={{
                          ...data,
                          title: data.title[currentLang],
                          text: data.text,
                          textSub: data.textSub
                        }}
                        key={data.id}
                      />
                    ))}
                    <article
                      className="themesflat-spacer clearfix"
                      data-desktop={42}
                      data-mobile={35}
                      data-smobile={35}
                    />
                  </article>
                </article>

                <IconBox />
                <article className="row">
                  <article className="col-md-12">
                    <article
                      className="themesflat-spacer clearfix"
                      data-desktop={41}
                      data-mobile={35}
                      data-smobile={35}
                    />
                    <article className="elm-button text-center">
                      <Link to="#" className="themesflat-button bg-accent">
                        {pageData.aboutButton[currentLang]}
                      </Link>
                    </article>
                    <article
                      className="themesflat-spacer clearfix"
                      data-desktop={73}
                      data-mobile={60}
                      data-smobile={60}
                    />
                  </article>
                </article>
              </article>
            </article>
            <About />
            <Counter />
            {/* ... rest of the components ... */}
            <article className="row-quote bg-row-1">
              <article className="container">
                <article className="row">
                  <article className="col-md-12">
                    <article
                      className="themesflat-spacer clearfix"
                      data-desktop={40}
                      data-mobile={60}
                      data-smobile={60}
                    />
                    <article className="themesflat-quote style-1 clearfix">
                      <article className="quote-item">
                        <article className="inner">
                          <article className="heading-wrap">
                            <h3 className="heading">
                              {pageData.executeProject[currentLang]}
                            </h3>
                          </article>
                          <article className="button-wrap has-icon icon-left">
                            <Link
                              to="/contact-01"
                              className="themesflat-button bg-white small"
                            >
                              <span>
                                +966 50 955 3440
                                <span className="icon">
                                  <i className="autora-icon-phone-contact" />
                                </span>
                              </span>
                            </Link>
                          </article>
                        </article>
                      </article>
                    </article>
                    <article
                      className="themesflat-spacer clearfix"
                      data-desktop={31}
                      data-mobile={60}
                      data-smobile={60}
                    />
                  </article>
                </article>
              </article>
            </article>
            <article className="row-tabs">
              <article className="container">
                <article className="row">
                  <article className="col-md-12">
                    <article
                      className="themesflat-spacer clearfix"
                      data-desktop={61}
                      data-mobile={60}
                      data-smobile={60}
                    />
                  </article>
                </article>
                <article className="row">
                  <Overview currentLang={currentLang} />
                  <Faq currentLang={currentLang} />
                </article>
              </article>
            </article>
            <Partner />
          </article>
          <Footer currentLang={currentLang} />
          <BottomBar activePage="about" currentLang={currentLang} />
        </article>
      </article>
    </article>
  );
};

export default AboutUs;