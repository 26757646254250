import React from "react";
import { mapConfig } from "../../../config/mapConfig";
const Information = ({ currentLang = "en", contactInfo }) => {
  const { coordinates } = mapConfig;
  
  // Format coordinates for OpenStreetMap
  // const { latitude, longitude, zoom } = coordinates;
  // const mapUrl = `https://www.openstreetmap.org/export/embed.html?bbox=${
  //   longitude - 0.01
  // }%2C${latitude - 0.005}%2C${longitude + 0.01}%2C${
  //   latitude + 0.005
  // }&layer=mapnik&marker=${latitude}%2C${longitude}&zoom=${zoom}`;

  const infoStyle = {
    textAlign: currentLang === "ar" ? "right" : "left",
    direction: currentLang === "ar" ? "rtl" : "ltr"
  };

  return (
    <article className="col-md-6" style={infoStyle}>
      <article className="contact-info">
        <h2 className="heading" style={{
          display: 'inline-block',
          borderBottom: '2px solid #dda939',
          paddingBottom: '10px',
          marginBottom: '20px',
          fontSize:"24px"
        }}>
          {currentLang === "ar" ? "معلومات الاتصال" : "INFORMATION"}
        </h2>

        <article className="office-title" style={{
          backgroundColor: '#f7f7f7',
          padding: '10px 15px',
          marginBottom: '20px',
          borderRadius: '4px',
          width: 'fit-content',
        }}>
          {currentLang === "ar" ? "المكتب الرئيسي" : "Head Office"}
        </article>

        <article className="contact-info-content">
          <article className="info-item" style={{ display: 'flex', marginBottom: '15px', gap: '15px' }}>
            <article style={{
              backgroundColor: '#dda939',
              padding: '10px',
              borderRadius: '4px',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <i className="fa fa-map-marker" style={{ color: 'white' }}></i>
            </article>
            <article style={{ flex: 1 }}>{contactInfo.address[currentLang]}</article>
          </article>

          <article className="info-item" style={{ display: 'flex', marginBottom: '15px', gap: '15px' }}>
            <article style={{
              backgroundColor: '#dda939',
              padding: '10px',
              borderRadius: '4px',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <i className="fa fa-phone" style={{ color: 'white' }}></i>
            </article>
            <article style={{ flex: 1 }}>{contactInfo.phone.numbers}</article>
          </article>

          <article className="info-item" style={{ display: 'flex', marginBottom: '15px', gap: '15px' }}>
            <article style={{
              backgroundColor: '#dda939',
              padding: '10px',
              borderRadius: '4px',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <i className="fa fa-envelope" style={{ color: 'white' }}></i>
            </article>
            <article style={{ flex: 1 }}>{contactInfo.email.addresses}</article>
          </article>

          <article className="info-item" style={{ display: 'flex', marginBottom: '15px', gap: '15px' }}>
            <article style={{
              backgroundColor: '#dda939',
              padding: '10px',
              borderRadius: '4px',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <i className="fa fa-clock-o" style={{ color: 'white' }}></i>
            </article>
            <article style={{ flex: 1 }}>{contactInfo.hours.times[currentLang]}</article>
          </article>
        </article>

        <article className="map-container" style={{ marginTop: '20px' }}>
          <iframe
            title={currentLang === "ar" ? "موقع المكتب الرئيسي" : "Head Office Location"}
            src={`https://www.openstreetmap.org/export/embed.html?bbox=${coordinates.longitude - 0.01}%2C${coordinates.latitude - 0.005}%2C${coordinates.longitude + 0.01}%2C${coordinates.latitude + 0.005}&layer=mapnik&marker=${coordinates.latitude}%2C${coordinates.longitude}`}
            style={{ border: 0, width: '100%', height: '300px', borderRadius: '4px' }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </article>
      </article>
    </article>
  );
};

export default Information;
