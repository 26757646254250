import React from "react";

const faqData = [
  {
    id: 1,
    classname: "accordion-item active",
    heading: {
      en: "What types of construction projects do you handle?",
      ar: "ما هي أنواع مشاريع البناء التي تتعاملون معها؟",
    },
    text: {
      en: "We handle a wide range of construction projects including residential complexes, commercial buildings, industrial facilities, and government projects. Our expertise covers new construction, renovation, and expansion projects across Saudi Arabia.",
      ar: "نتعامل مع مجموعة واسعة من مشاريع البناء تشمل المجمعات السكنية والمباني التجارية والمنشآت الصناعية والمشاريع الحكومية. تغطي خبرتنا مشاريع البناء الجديدة والتجديد والتوسعة في جميع أنحاء المملكة العربية السعودية.",
    },
  },
  {
    id: 2,
    classname: "accordion-item",
    heading: {
      en: "What are your quality assurance standards?",
      ar: "ما هي معايير ضمان الجودة لديكم؟",
    },
    text: {
      en: "We adhere to the highest international quality standards and local Saudi building codes. Our projects are supervised by certified engineers, and we implement strict quality control measures at every stage of construction.",
      ar: "نلتزم بأعلى المعايير الدولية للجودة وأنظمة البناء السعودية. يتم الإشراف على مشاريعنا من قبل مهندسين معتمدين، ونطبق إجراءات صارمة لمراقبة الجودة في كل مرحلة من مراحل البناء.",
    },
  },
  {
    id: 3,
    classname: "accordion-item",
    heading: {
      en: "How do you ensure project timelines and budgets?",
      ar: "كيف تضمنون الالتزام بمواعيد المشاريع والميزانيات؟",
    },
    text: {
      en: "We utilize advanced project management systems and maintain detailed scheduling. Our experienced team conducts thorough planning, regular progress monitoring, and implements effective cost control measures to ensure projects are delivered on time and within budget.",
      ar: "نستخدم أنظمة متقدمة لإدارة المشاريع ونحتفظ بجداول زمنية مفصلة. يقوم فريقنا المتمرس بالتخطيط الشامل والمراقبة المنتظمة للتقدم وتنفيذ تدابير فعالة لمراقبة التكاليف لضمان تسليم المشاريع في الوقت المحدد وضمن الميزانية.",
    },
  },
  {
    id: 4,
    classname: "accordion-item",
    heading: {
      en: "What warranties and after-service support do you provide?",
      ar: "ما هي الضمانات وخدمات ما بعد التنفيذ التي تقدمونها؟",
    },
    text: {
      en: "We provide comprehensive warranties on all our construction work, typically ranging from 1 to 10 years depending on the project type. Our after-service team is available for maintenance and support throughout the warranty period.",
      ar: "نقدم ضمانات شاملة على جميع أعمال البناء لدينا، تتراوح عادةً من سنة إلى 17 سنوات حسب نوع المشروع. فريق خدمات ما بعد التنفيذ لدينا متاح للصيانة والدعم طوال فترة الضمان.",
    },
  },
];

const Faq = ({ currentLang = "en" }) => {
  return (
    <article className="col-md-6">
      <article
        className="themesflat-spacer clearfix"
        data-desktop={0}
        data-mobile={0}
        data-smobile={35}
      />
      <article
        className="themesflat-content-box"
        data-margin="0 0 0 18px"
        data-mobilemargin="0 0 0 0"
      >
        <article className="themesflat-headings style-1 clearfix">
          <h2 className="heading" style={{ textAlign: currentLang === 'ar' ? 'right' : 'left' }}>
            {currentLang === "en" ? "FAQs" : "الأسئلة الشائعة"}
          </h2>
          <article className={`sep has-width w80 accent-bg margin-top-11 clearfix ${currentLang === 'ar' ? 'float-right' : ''}`} />
        </article>
        <article
          className="themesflat-spacer clearfix"
          data-desktop={38}
          data-mobile={35}
          data-smobile={35}
        />
        <article 
          className={`themesflat-accordions style-1 has-icon ${currentLang === 'ar' ? 'icon-right' : 'icon-left'} iconstyle-1 clearfix`}
          style={{ direction: currentLang === 'ar' ? 'rtl' : 'ltr' }}
        >
          {faqData.map((data) => (
            <article className={data.classname} key={data.id}>
              <h3 className="accordion-heading">
                <span className="inner" style={{ 
                  textAlign: currentLang === 'ar' ? 'right' : 'left',
                  display: 'block',
                  paddingLeft: currentLang === 'ar' ? '0' : '50px',
                  paddingRight: currentLang === 'ar' ? '50px' : '0'
                }}>
                  {data.heading[currentLang]}
                </span>
              </h3>
              <article className="accordion-content">
                <article 
                  className="fw-bold fs-4"
                  style={{ 
                    textAlign: currentLang === 'ar' ? 'right' : 'left',
                    direction: currentLang === 'ar' ? 'rtl' : 'ltr',
                    paddingRight: currentLang === 'ar' ? '50px' : '0',
                  }}
                >
                  {data.text[currentLang]}
                </article>
              </article>
            </article>
          ))}
        </article>
      </article>
    </article>
  );
};

export default Faq;
