import React from "react";
import { FormContact, Information } from "../layouts/contact";
import { Header, Footer, TopBar, BottomBar } from "../layouts/general/index";
import { useTranslation } from "react-i18next";

const Contact01 = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const headerData = {
    id: 1,
    logoweb: "assets/img/logo-small.png",
    names: currentLang === "en" ? "Contact" : "اتصل بنا",
  };

  const contactInfo = {
    address: {
      en: "Khurais Br Rd, An Nasim Al Gharbi, Riyadh 14231, Saudi Arabia",
      ar: "طريق خريص الفرعي، النسيم الغربي، الرياض ١٤٢٣١، المملكة العربية السعودية",
    },
    phone: {
      label: {
        en: "Phone",
        ar: "الهاتف",
      },
      numbers: "+966 50 955 3440",
    },
    email: {
      label: {
        en: "Email",
        ar: "البريد الإلكتروني",
      },
      addresses: ["info@construction.sa"],
    },
    hours: {
      label: {
        en: "Business Hours",
        ar: "ساعات العمل",
      },
      times: {
        en: "Saturday - Thursday: 24 Hours",
        ar: "السبت - الخميس: ٢٤ ساعة",
      },
    
    },
    mapLocation: {
      lat: 24.7405861,
      lng: 46.8049391,
      zoom: 17,
      title: "Elite Arab Contracting Est.",
      googleMapUrl:
        "https://www.google.com/maps/place/%D9%85%D8%A4%D8%B3%D8%B3%D8%A9+%D8%A7%D9%8A%D9%84%D9%8A%D8%AA+%D8%A7%D9%84%D8%B9%D8%B1%D8%A8+%D9%84%D9%84%D9%85%D9%82%D8%A7%D9%88%D9%84%D8%A7%D8%AA+%D8%A7%D9%84%D8%B9%D8%A7%D9%85%D8%A9%E2%80%AD/@24.7405861,46.8049391,17z",
    },
  };

  return (
    <article className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
      <article id="wrapper" className="animsition">
        <article id="page" className="clearfix">
          <article id="site-header-wrap">
            <TopBar />
            <Header data={headerData} currentLang={currentLang} />
          </article>
          <article id="main-content" className="site-main clearfix">
            <article id="content-wrap">
              <article id="site-content" className="site-content clearfix">
                <article id="inner-content" className="inner-content-wrap">
                  <article className="page-content">
                    <article className="row-contact">
                      <article className="container">
                        <article className="row">
                          <article className="col-md-12">
                            <article
                              className="themesflat-spacer clearfix"
                              data-desktop={61}
                              data-mobile={60}
                              data-smobile={60}
                            />
                          </article>
                        </article>
                        <article className="row">
                          <FormContact currentLang={currentLang} />
                          <Information currentLang={currentLang} contactInfo={contactInfo} />
                        </article>
                      </article>
                    </article>
                  </article>
                </article>
              </article>
            </article>
          </article>
          <Footer currentLang={currentLang} />
          <BottomBar currentLang={currentLang} />
        </article>
      </article>
    </article>
  );
};

export default Contact01;
