import React from "react";
import { Link } from "react-router-dom";

const servicesData = [
  {
    id: 1,
    colspan: [
      {
        idx: 1,
        icon: "/assets/icon/2.png",
        iconHover: "/assets/icon/1.png",
        title: {
          en: "DESIGN-BUILD",
          ar: "التصميم والبناء",
        },
        text: {
          en: "We develop innovative design solutions that meet the unique requirements of projects in Saudi Arabia, combining modern architecture with local cultural elements.",
          ar: "نطور حلولاً تصميمية مبتكرة تلبي المتطلبات الفريدة للمشاريع في المملكة العربية السعودية، مع الجمع بين العمارة الحديثة والعناصر الثقافية المحلية.",
        },
      },
    ],
    colleft: [
      {
        idx: 1,
        icon: "/assets/icon/6.png",
        iconHover: "/assets/icon/5.png",
        title: {
          en: "PRECONSTRUCTION SERVICES",
          ar: "خدمات ما قبل البناء",
        },
        text: {
          en: "Our expert team provides comprehensive planning and consultation services, ensuring compliance with Saudi building codes and regulations.",
          ar: "يقدم فريقنا المتخصص خدمات شاملة للتخطيط والاستشارات، مع ضمان الامتثال لأنظمة ولوائح البناء السعودية.",
        },
      },
    ],
  },
  {
    id: 2,
    colspan: [
      {
        idx: 1,
        icon: "/assets/icon/11.png",
        iconHover: "/assets/icon/12.png",
        title: {
          en: "CONSTRUCTION MANAGEMENT",
          ar: "إدارة البناء",
        },
        text: {
          en: "Our project management team ensures efficient execution of construction projects across the Kingdom, from residential complexes to commercial buildings.",
          ar: "يضمن فريق إدارة المشاريع لدينا التنفيذ الفعال لمشاريع البناء في جميع أنحاء المملكة، من المجمعات السكنية إلى المباني التجارية.",
        },
      },
    ],
    colleft: [
      {
        id: 2,
        idx: 1,
        icon: "/assets/icon/7.png",
        iconHover: "/assets/icon/8.png",
        title: {
          en: "BUILDING ENVELOPES",
          ar: "أغلفة المباني",
        },
        text: {
          en: "Our in-house cladding teams supply and install a articleerse range of solutions to new building and building envelope upgrade projects.",
          ar: "تقوم فرق التكسية لدينا بتوريد وتركيب مجموعة متنوعة من الحلول للمباني الجديدة ومشاريع تحديث غلاف المبنى.",
        },
      },
    ],
  },
  {
    id: 3,
    colspan: [
      {
        idx: 1,
        icon: "/assets/icon/10.png",
        iconHover: "/assets/icon/9.png",
        title: {
          en: "STEEL STRUCTURES",
          ar: "الهياكل الفولاذية",
        },
        text: {
          en: "We have completed numerous steel structure projects across Saudi Arabia, including commercial centers, warehouses, and industrial facilities.",
          ar: "أنجزنا العديد من مشاريع الهياكل الفولاذية في جميع أنحاء المملكة العربية السعودية، بما في ذلك المراكز التجارية والمستودعات والمنشآت الصناعية.",
        },
      },
    ],
    colleft: [
      {
        idx: 1,
        id: 3,
        icon: "/assets/icon/3.png",
        iconHover: "/assets/icon/4.png",
        title: {
          en: "CONCRETE STRUCTURES",
          ar: "الهياكل الخرسانية",
        },
        text: {
          en: "Our specialized team executes high-quality concrete work for various projects, from luxury villas to large-scale commercial developments in Saudi Arabia.",
          ar: "ينفذ فريقنا المتخصص أعمال الخرسانة عالية الجودة لمختلف المشاريع، من الفلل الفاخرة إلى المشاريع التجارية الكبرى في المملكة العربية السعودية.",
        },
      },
    ],
  },
];

const ServicesBox = ({ currentLang = "en" }) => {
  return (
		<article className="themesflat-row gutter-30 clearfix">
			{servicesData.map((data) => (
				<article className="col span_1_of_4" key={data.id}>
					<article
						className="themesflat-spacer clearfix"
						data-desktop={0}
						data-mobile={35}
						data-smobile={35}
					/>
					{data.colleft.map((spandata) => (
						<article
							className="themesflat-icon-box accent-color style-2 clearfix"
							key={spandata.idx}
							style={{
								display: "flex",
								transition: "all 0.3s ease",
								minHeight: "150px",
							}}
							onMouseEnter={(e) => {
								const icon =
									e.currentTarget.getElementsByClassName("service-icon")[0];
								icon.style.opacity = "0";
								setTimeout(() => {
									icon.src = spandata.iconHover;
									icon.style.opacity = "1";
								}, 150);
							}}
							onMouseLeave={(e) => {
								const icon =
									e.currentTarget.getElementsByClassName("service-icon")[0];
								icon.style.opacity = "0";
								setTimeout(() => {
									icon.src = spandata.icon;
									icon.style.opacity = "1";
								}, 150);
							}}
						>
							{currentLang === "en" && (
								<article className="icon-wrap mr-4">
									<article className="service-icon-container">
										<img
											src={spandata.icon}
											alt={spandata.title[currentLang]}
											loading="lazy"
											className="service-icon"
											style={{
												transition: "opacity 0.3s ease",
												width: "50px",
												height: "50px",
											}}
										/>
									</article>
								</article>
							)}
							<article
								className="text-wrap"
								style={{ textAlign: currentLang === "ar" ? "right" : "left" }}
							>
								<h5 className="heading">
									<Link to="#">{spandata.title[currentLang]}</Link>
								</h5>
								<p
									className="sub-heading"
									style={{
										fontSize: "1.5rem",
										fontWeight: 600,
									}}
								>
									{spandata.text[currentLang]}
								</p>
							</article>
							{currentLang === "ar" && (
								<article className="icon-wrap ml-4">
									<article className="service-icon-container">
										<img
											src={spandata.icon}
											loading="lazy"
											alt={spandata.title[currentLang]}
											className="service-icon"
											style={{
												width: "50px",
												height: "50px",
											}}
										/>
									</article>
								</article>
							)}
						</article>
					))}
					<article
						className="themesflat-spacer clearfix"
						data-desktop={62}
						data-mobile={35}
						data-smobile={35}
					/>
					{data.colspan.map((spandata) => (
						<article
							className="themesflat-icon-box accent-color style-2 clearfix"
							key={spandata.idx}
							style={{
								display: "flex",
								//  alignItems: 'center',
								transition: "all 0.3s ease",
								minHeight: "150px",
							}}
							onMouseEnter={(e) => {
								const icon =
									e.currentTarget.getElementsByClassName("service-icon")[0];
								icon.style.opacity = "0";
								setTimeout(() => {
									icon.src = spandata.iconHover;
									icon.style.opacity = "1";
								}, 150);
							}}
							onMouseLeave={(e) => {
								const icon =
									e.currentTarget.getElementsByClassName("service-icon")[0];
								icon.style.opacity = "0";
								setTimeout(() => {
									icon.src = spandata.icon;
									icon.style.opacity = "1";
								}, 150);
							}}
						>
							{currentLang === "en" && (
								<article className="icon-wrap mr-4">
									<article className="service-icon-container">
										<img
											loading="lazy"
											src={spandata.icon}
											alt={spandata.title[currentLang]}
											className="service-icon"
											style={{
												transition: "opacity 0.3s ease",
												width: "50px",
												height: "50px",
											}}
										/>
									</article>
								</article>
							)}
							<article
								className="text-wrap"
								style={{ textAlign: currentLang === "ar" ? "right" : "left" }}
							>
								<h5 className="heading">
									<Link to="#">{spandata.title[currentLang]}</Link>
								</h5>
								<p
									className="sub-heading"
									style={{
										fontSize: "1.5rem",
										fontWeight: 600,
									}}
								>
									{spandata.text[currentLang]}
								</p>
							</article>
							{currentLang === "ar" && (
								<article className="icon-wrap ml-4">
									<article className="service-icon-container">
										<img
											loading="lazy"
											src={spandata.icon}
											alt={spandata.title[currentLang]}
											className="service-icon"
											style={{
												width: "50px",
												height: "50px",
											}}
										/>
									</article>
								</article>
							)}
						</article>
					))}
				</article>
			))}
		</article>
	);
};

export default ServicesBox;
