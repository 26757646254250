import React from 'react';
import './Partner.css';

const partnerData = [
    {
        id: 1,
        img: 'assets/img/partners/1.png',
        
    },
    {
        id: 2,
        img: 'assets/img/partners/2.png',
        
    },
    {
        id: 3,
        img: 'assets/img/partners/3.png',
        
    },
    {
        id: 4,
        img: 'assets/img/partners/4.png',
        
    },
    {
        id: 5,
        img: 'assets/img/partners/5.png',
        
    },
    {
        id: 6,
        img: 'assets/img/partners/6.png',
        
    },
    {
        id: 7,
        img: 'assets/img/partners/7.png',
        
    },
    {
        id: 8,
        img: 'assets/img/partners/8.png',
        
    },
];

const Partner = () => {
    return (
        <article className="row-partner">
            <article className="container">
                <article className="row">
                    <article className="col-md-12">
                        <article className="themesflat-spacer clearfix" data-desktop={83} data-mobile={60} data-smobile={60} />
                        <article className="themesflat-carousel-box clearfix" data-gap={5} data-column={5} data-column2={3}
                            data-column3={2} data-auto="true">
                            <article className="owl-carousel owl-theme">
                                {
                                    partnerData.map(data => (
                                        <article className="themesflat-partner style-1 align-center clearfix" key={data.id}>
                                            <article className="partner-item">
                                                <article className="inner">
                                                    <article className="thumb">
                                                        <img
                                                            src={data.img}
                                                            alt="شريك مؤسسة ايليت للمقاولات - Elite Contractors Partner"
                                                            loading="lazy"
                                                            width="150"
                                                            height="100"
                                                            className="partner-default"
                                                        />
                                                        
                                                    </article>
                                                </article>
                                            </article>
                                        </article>
                                    ))
                                }
                            </article>
                        </article>
                        <article className="themesflat-spacer clearfix" data-desktop={66} data-mobile={60} data-smobile={60} />
                    </article>
                </article>
            </article>
        </article>
    );
}

export default Partner;