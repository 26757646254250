import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { FaTiktok, FaFacebookF, FaWhatsapp, FaInstagram } from "react-icons/fa";
import { FaSnapchat } from "react-icons/fa6";
import { mapConfig } from "../../../config/mapConfig";

const footerData = {
  companyInfo: {
    logo: "assets/Artboard 1.png",
    description: {
      en: "We have over 17 years of experience in construction and development across Saudi Arabia.",
      ar: "لدينا خبرة تزيد عن 17 عاماً في مجال البناء والتطوير في المملكة العربية السعودية.",
    },
    contact: [
      {
        icon: "fa fa-map-marker",
        text: {
          en: "Khurais Br Rd, An Nasim Al Gharbi",
          ar: "طريق خريص الفرعي، النسيم الغربي",
        },
        subText: {
          en: "Riyadh 14231, Saudi Arabia",
          ar: "الرياض ١٤٢٣١، المملكة العربية السعودية",
        },
      },
      {
        icon: "fa fa-phone",
        text: {
          en: "CALL US: +966 50 955 3440",
          ar: "اتصل بنا: ٩٦٦٥٠٩٥٥٣٤٤٠+",
        },
      },
     
      {
        icon: "fa fa-envelope",
        text: {
          en: "info@eliteconstructions.co",
          ar: "info@eliteconstructions.co",
        },
      },
      {
        icon: "fa fa-envelope",
        text: {
          en: "sales@eliteconstructions.co",
          ar: "sales@eliteconstructions.co",
        },
      },
    ],
  },



};

const Footer = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const [paddingLeft, setPaddingLeft] = useState(0);

  useEffect(() => {
    const updatePadding = () => {
      if (window.innerWidth >= 1200) {
        setPaddingLeft(285);
      } else {
        setPaddingLeft(0);
      }
      if (window.innerWidth >= 992) {
        setPaddingLeft(120);
      }
      if (window.innerWidth >= 768) {
        setPaddingLeft(120);
      }
    };

    updatePadding();
    window.addEventListener('resize', updatePadding);

    return () => {
      window.removeEventListener('resize', updatePadding);
    };
  }, []);

  return (
		<footer id="footer" className="clearfix" style={{ paddingLeft }}>
			<article id="footer-widgets" className="container footer-row">
				<article className="themesflat-row gutter-30">
					<article className="col span_1_of_4">
						<article className="widget widget_text">
							<article className="textwidget">
								<p>
									<img
										loading="lazy"
										src="assets/Artboard1.png"
										alt="مؤسسة ايليت للمقاولات - شركة مقاولات بالرياض"
										style={{ width: "145px", marginTop: "-35px" }}
										width={60}
										height={30}
										data-retina="assets/Artboard1.png"
										data-width={146}
										data-height={30}
									/>
								</p>
								<p className="margin-bottom-15">
									{footerData.companyInfo.description[currentLang]}
								</p>
								<ul>
									{footerData.companyInfo.contact.map((item, index) => (
										<li key={index}>
											<article className="inner">
												<span className={item.icon} />
												<span className="text">
													{item.icon === "fa fa-envelope" ? (
														<a
															href={`mailto:${item.text[currentLang]}`}
															style={{
																color: "inherit",
																textDecoration: "none",
															}}
														>
															{item.text[currentLang]}
														</a>
													) : (
														<>
															{item.text[currentLang]}
															{item.subText && (
																<span className="sl">
																	{item.subText[currentLang]}
																</span>
															)}
														</>
													)}
												</span>
											</article>
										</li>
									))}
								</ul>
							</article>
						</article>
					</article>

					<article
						className="col span_1_of_4"
						style={{ height: "250px", overflow: "hidden" }}
					>
						<article className="widget widget_map">
							<h2 className="widget-title">
								<span>{currentLang === "en" ? "FIND US" : "موقعنا"}</span>
							</h2>
							<article
								className="map-container"
								style={{
									...mapConfig.styles,
									marginTop: "20px",
								}}
							>
								<iframe
									src={`https://www.openstreetmap.org/export/embed.html?bbox=${
										mapConfig.coordinates.longitude - 0.01
									}%2C${mapConfig.coordinates.latitude - 0.005}%2C${
										mapConfig.coordinates.longitude + 0.01
									}%2C${
										mapConfig.coordinates.latitude + 0.005
									}&layer=mapnik&marker=${mapConfig.coordinates.latitude}%2C${
										mapConfig.coordinates.longitude
									}&zoom=${mapConfig.coordinates.zoom}`}
									style={{
										...mapConfig.styles,
										height: "180px",
									}}
									allowFullScreen=""
									loading="lazy"
									referrerPolicy="no-referrer-when-downgrade"
									title={
										currentLang === "en" ? "Office Location" : "موقع المكتب"
									}
								/>
							</article>
						</article>
					</article>

					<article className="col span_1_of_4">
						<article className="widget widget_socials">
							<h2 className="widget-title">
								<span>{currentLang === "en" ? "FOLLOW US" : "تابعنا"}</span>
							</h2>
							<article className="footer-socials">
								<a
									href="https://www.tiktok.com/@elitearabcontractors"
									target="_blank"
									rel="noopener noreferrer"
									style={{ marginRight: "15px" }}
								>
									<FaTiktok size={24} />
								</a>
								<a
									href="https://www.snapchat.com/add/rehlatalsalamco?share_id=LdpDKAcJkiQ&locale=ar-EG"
									target="_blank"
									rel="noopener noreferrer"
									style={{ marginRight: "15px" }}
								>
									<FaSnapchat size={24} />
								</a>
								<a
									href="https://www.facebook.com/profile.php?id=61565901014891&mibextid=ZbWKwL"
									target="_blank"
									rel="noopener noreferrer"
									style={{ marginRight: "15px" }}
								>
									<FaFacebookF size={24} />
								</a>
								<a
									href="https://www.instagram.com/elitearab2/?igsh=aGg2MXc4OGd1MXY%3D"
									target="_blank"
									rel="noopener noreferrer"
									style={{ marginRight: "15px" }}
								>
									<FaInstagram size={24} />
								</a>
								<a
									href="https://wa.me/966509553440"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FaWhatsapp size={24} />
								</a>
							</article>
						</article>
					</article>
				</article>
			</article>
		</footer>
	);
};

export default Footer;
