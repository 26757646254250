import React from "react";
import { SidebarBlog, ContentBlog } from "../layouts/blog";
import { Header, Footer, TopBar, BottomBar } from "../layouts/general/index";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const headerData = {
    id: 1,
    logoweb: "assets/img/logo-small.png",
    names: currentLang === "en" ? "Blog" : "المدونة",
  };

  return (
    <article className="header-fixed sidebar-right header-style-2 topbar-style-1 menu-has-search">
      <article id="wrapper" className="animsition">
        <article id="page" className="clearfix">
          <article id="site-header-wrap">
            <TopBar />
            <Header data={headerData} currentLang={currentLang} />
          </article>
          <article id="main-content" className="site-main clearfix">
            <article id="content-wrap" className="container">
              <article
                className="themesflat-spacer clearfix"
                data-desktop={60}
                data-mobile={60}
                data-smobile={60}
              />
              <ContentBlog currentLang={currentLang} />
              <SidebarBlog currentLang={currentLang} />
              <article
                className="themesflat-spacer clearfix"
                data-desktop={80}
                data-mobile={60}
                data-smobile={60}
              />
            </article>
          </article>
          <Footer />
          <BottomBar data={headerData} currentLang={currentLang} />
        </article>
      </article>
    </article>
  );
};

export default Blog;
