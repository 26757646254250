import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const aboutData = {
  title: {
    en: "ًWelcome to Elite",
    ar: "مرحباً بكم في إيليت",
  },
  description: {
    en: "Elite stands out with an honorable history in the construction field, capturing attention with its impressive track record and excellent reputation built through dedication to achieving client aspirations.",
    ar: "تتألق مؤسسة إيليت بتاريخ مشرف في ميدان البناء، حيث خطفت الأنظار بسجل إنجازاتها المذهل وسمعتها الطيبة التي تراكمت بفضل تفانيها في تحقيق تطلعات عملائها.",
  },
  listItems: [
    {
      id: 1,
      icon: "fa fa-check-square",
      text: {
        en: "Completing projects on time & Following budget guidelines",
        ar: "إنجاز المشاريع في الوقت المحدد وفقاً للميزانية",
      },
    },
    {
      id: 2,
      icon: "fa fa-check-square",
      text: {
        en: "Elevated quality of workmanship",
        ar: "جودة عالية في التنفيذ",
      },
    },
    {
      id: 3,
      icon: "fa fa-check-square",
      text: {
        en: "Meeting articleerse supplier requirements",
        ar: "تلبية متطلبات الموردين المختلفة",
      },
    },
    {
      id: 4,
      icon: "fa fa-check-square",
      text: {
        en: "Implementing appropriate safety precautions and procedures",
        ar: "تطبيق إجراءات واحتياطات السلامة المناسبة",
      },
    },
  ],
  button: {
    en: "GET IN TOUCH",
    ar: "تواصل معنا",
  },
};

const About = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const isRTL = currentLang === "ar";

  return (
    <article className="row-about">
      <article className="container-fluid">
        <article className="row equalize sm-equalize-auto">
          {!isRTL ? (
            <>
              <article className="col-md-6 half-background style-1" />
              <article className="col-md-6" style={{ background: "#0b0b22" }}>
                <article
                  className="themesflat-spacer clearfix"
                  data-desktop={64}
                  data-mobile={60}
                  data-smobile={60}
                />
                <article
                  className="themesflat-content-box clearfix"
                  data-margin="0 25% 0 4.5%"
                  data-mobilemargin="0 0 0 4.5%"
                >
                  <article className="themesflat-headings style-1 clearfix">
                    <h2
                      className="heading"
                      style={{
                        color: "#dda939",
                        textAlign: currentLang === "ar" ? "right" : "left",
                        direction: currentLang === "ar" ? "rtl" : "ltr",
                      }}
                    >
                      {aboutData.title[currentLang]}
                    </h2>
                    <article
                      className="sep has-width w80 accent-bg margin-top-11 clearfix"
                      style={{
                        marginLeft: currentLang === "ar" ? "auto" : "0",
                        marginRight: currentLang === "ar" ? "0" : "auto",
                      }}
                    />
                    <p
                      className="sub-heading margin-top-30"
                      style={{
                        fontSize: "18px",
                        lineHeight: "1.8",
                        color: "#fff",
                        textAlign: currentLang === "ar" ? "right" : "left",
                        direction: currentLang === "ar" ? "rtl" : "ltr",
                      }}
                    >
                      {aboutData.description[currentLang]}
                    </p>
                  </article>
                  <article
                    className="themesflat-spacer clearfix"
                    data-desktop={26}
                    data-mobile={35}
                    data-smobile={35}
                  />
                  <article className="content-list">
                    <article className="themesflat-list has-icon style-1 icon-left clearfix">
                      <article className="inner">
                        {aboutData.listItems.map((item) => (
                          <span
                            className="item"
                            key={item.id}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "9px",
                              direction: currentLang === "ar" ? "rtl" : "ltr",
                              textAlign: currentLang === "ar" ? "right" : "left",
                              justifyContent: currentLang === "ar" ? "flex-end" : "flex-start",
                            }}
                          >
                            <span
                              className="icon"
                              style={{
                                color: "#dda939",
                                marginLeft: currentLang === "ar" ? "0" : "10px",
                                marginRight: currentLang === "ar" ? "10px" : "0",
                                order: currentLang === "ar" ? "-1" : "1"
                              }}
                            >
                              <i className={item.icon} />
                            </span>
                            <span
                              className="text"
                              style={{
                                color: "#fff",
                                flex: 1,
                              }}
                            >
                              {item.text[currentLang]}
                            </span>
                            
                          </span>
                        ))}
                      </article>
                    </article>
                  </article>
                  <article
                    className="themesflat-spacer clearfix"
                    data-desktop={42}
                    data-mobile={35}
                    data-smobile={35}
                  />
                  <article
                    className="elm-button"
                    style={{
                      textAlign: currentLang === "ar" ? "right" : "left",
                    }}
                  >
                    <Link
                      to="/contact-01"
                      onClick={() => {
                        window.location.hash = "/contact-01";
                        window.location.reload();
                      }}
                      className="themesflat-button bg-white"
                      style={{
                        fontSize: "16px",
                        padding: "15px 30px",
                        color: "#fff",
                        backgroundColor: "transparent",
                        border: "1px solid #fff",
                      }}
                    >
                      {aboutData.button[currentLang]}
                    </Link>
                  </article>
                </article>
                <article
                  className="themesflat-spacer clearfix"
                  data-desktop={75}
                  data-mobile={60}
                  data-smobile={60}
                />
              </article>
            </>
          ) : (
            <>
              <article className="col-md-6" style={{ background: "#0b0b22" }}>
                <article
                  className="themesflat-spacer clearfix"
                  data-desktop={64}
                  data-mobile={60}
                  data-smobile={60}
                />
                <article
                  className="themesflat-content-box clearfix"
                  data-margin="0 4.5% 0 25%"
                  data-mobilemargin="0 4.5% 0 0"
                  style={{ direction: "rtl" }}
                >
                  <article className="themesflat-headings style-1 clearfix">
                    <h2
                      className="heading"
                      style={{
                        color: "#dda939",
                        textAlign: currentLang === "ar" ? "right" : "left",
                        direction: currentLang === "ar" ? "rtl" : "ltr",
                      }}
                    >
                      {aboutData.title[currentLang]}
                    </h2>
                    <article
                      className="sep has-width w80 accent-bg margin-top-11 clearfix"
                      style={{
                        marginLeft: currentLang === "ar" ? "auto" : "0",
                        marginRight: currentLang === "ar" ? "0" : "auto",
                      }}
                    />
                    <p
                      className="sub-heading margin-top-30"
                      style={{
                        fontSize: "18px",
                        lineHeight: "1.8",
                        color: "#fff",
                        textAlign: currentLang === "ar" ? "right" : "left",
                        direction: currentLang === "ar" ? "rtl" : "ltr",
                      }}
                    >
                      {aboutData.description[currentLang]}
                    </p>
                  </article>
                  <article
                    className="themesflat-spacer clearfix"
                    data-desktop={26}
                    data-mobile={35}
                    data-smobile={35}
                  />
                  <article className="content-list">
                    <article className="themesflat-list has-icon style-1 icon-left clearfix">
                      <article className="inner">
                        {aboutData.listItems.map((item) => (
                          <span
                            className="item"
                            key={item.id}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "9px",
                              direction: currentLang === "ar" ? "rtl" : "ltr",
                              textAlign: currentLang === "ar" ? "right" : "left",
                              justifyContent: currentLang === "ar" ? "flex-end" : "flex-start",
                            }}
                          >
                            {currentLang === "ar" && (
                              <span
                                style={{
                                  color: "#dda939",
                                  marginLeft: currentLang === "ar" && "10px",
                                  marginRight: currentLang === "ar" && "10px",
                                  order: currentLang === "ar" && "-1",
                                }}
                              >
                                <i className={item.icon} />
                              </span>
                            )}
                            <span
                              className="text"
                              style={{
                                color: "#fff",
                                flex: 1,
                              }}
                            >
                              {item.text[currentLang]}
                            </span>
                          </span>
                        ))}
                      </article>
                    </article>
                  </article>
                  <article
                    className="themesflat-spacer clearfix"
                    data-desktop={42}
                    data-mobile={35}
                    data-smobile={35}
                  />
                  <article
                    className="elm-button"
                    style={{
                      textAlign: currentLang === "ar" ? "right" : "left",
                    }}
                  >
                    <Link
                      to="/contact-01"
                      onClick={() => {
                        window.location.hash = "/contact-01";
                        window.location.reload();
                      }}
                      className="themesflat-button bg-white"
                      style={{
                        fontSize: "16px",
                        padding: "15px 30px",
                        color: "#fff",
                        backgroundColor: "transparent",
                        border: "1px solid #fff",
                      }}
                    >
                      {aboutData.button[currentLang]}
                    </Link>
                  </article>
                </article>
                <article
                  className="themesflat-spacer clearfix"
                  data-desktop={75}
                  data-mobile={60}
                  data-smobile={60}
                />
              </article>
              <article className="col-md-6 half-background style-1" />
            </>
          )}
        </article>
      </article>
    </article>
  );
};

export default About;
