const menus = [
  {
    id: 1,
    name: "Home",
    link: "/",
    hasDropdown: false,
  },
  {
    id: 2,
    name: "About Us",
    link: "/about-us",
    hasDropdown: false,
  },
  {
    id: 3,
    name: "Services",
    link: "/services",
    hasDropdown: false,
  },
  {
    id: 4,
    name: "Projects",
    link: "/project",
    hasDropdown: false,
  },
  {
    id: 6,
    name: "Blog",
    link: "/blog",
    hasDropdown: false,
  },
  {
    id: 7,
    name: "Contact",
    link: "/contact-01",
    hasDropdown: false,
  },
];

export default menus;
