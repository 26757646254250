
import Home01 from "./Home01";
import AboutUs from "./AboutUs";

import Services from "./Services";

import Projects from "./Projects";

import Blog from "./Blog";

import Contact01 from "./Contact01";

const routes = [
	{ path: "/", component: Home01 },
	{ path: "/about-us", component: AboutUs },
	{ path: "/services", component: Services },
	{ path: "/project", component: Projects },
	{ path: "/blog", component: Blog },
	{ path: "/contact-01", component: Contact01 },
];

export default routes;
