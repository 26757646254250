const blogData = [
  {
    id: 19,
    srcimg: "/assets/img/blog/11.jpg",
    title: {
      en: "Introduction",
      ar: "المقدمة",
    },
    day: "26",
    month: {
      en: "NOV",
      ar: "نوفمبر",
    },
    author: {
      en: "Admin",
      ar: "المدير",
    },
    comments: "0",
    descriptions: {
      en: "Contracting companies play a vital role in turning individuals' and companies' dreams into tangible projects. Successful projects depend on choosing the right company with experience, commitment, and high quality. In Riyadh, Elite Contracting Company stands out as one of the leading companies providing comprehensive construction and building services. We will showcase the company's key services that distinguish it as the first choice for clients seeking excellence and professionalism in executing their projects.",
      ar: "تلعب شركات المقاولات دورًا حيويًا في تحقيق أحلام الأفراد والشركات بتحويل أفكارهم إلى مشاريع ملموسة. تعتمد المشاريع الناجحة على اختيار الشركة المناسبة التي تمتلك الخبرة، الالتزام، والجودة العالية. في مدينة الرياض، تبرز شركة إيليت للمقاولات كواحدة من أبرز الشركات التي تقدم خدمات شاملة في مجال البناء والتشييد. سنستعرض أبرز الخدمات التي تقدمها الشركة، والتي تميزها كخيار أول للعملاء الباحثين عن التميز والاحترافية في تنفيذ مشاريعهم.",
    },
  },
  {
    id: 1,
    srcimg: "/assets/img/blog/1.jpg",
    title: {
      en: "Project Planning and Management",
      ar: "التخطيط وإدارة المشاريع",
    },
    day: "26",
    month: {
      en: "NOV",
      ar: "نوفمبر",
    },
    author: {
      en: "Admin",
      ar: "المدير",
    },
    comments: "0",
    descriptions: {
      en: "Project planning and management are crucial factors for any project's success. Elite Contracting Company provides integrated services to ensure the best results: Economic feasibility study: comprehensive analysis of costs, returns, and risks. Detailed timeline preparation: defining different project phases with precise execution times. Work progress monitoring: continuous oversight to ensure team compliance with schedules and costs, addressing any obstacles immediately. Technical team management: coordinating between specialized teams to ensure efficient project execution with high quality and full professional standards compliance.",
      ar: "يعد التخطيط وإدارة المشاريع من العوامل الحاسمة لنجاح أي مشروع. تقدم شركة إيليت للمقاولات خدمات متكاملة لضمان تحقيق أفضل النتائج: دراسة الجدوى الاقتصادية للمشروع: تحليل شامل للتكاليف والعوائد والمخاطر، لضمان أن المشروع مفيد من الناحية المالية. إعداد جدول زمني دقيق: تحديد المراحل المختلفة للمشروع مع تحديد أوقات تنفيذ دقيقة. متابعة سير العمل: مراقبة مستمرة للتأكد من التزام الفريق بالمواعيد والتكاليف المحددة، ومعالجة أي عقبات بشكل فوري. إدارة الفرق الفنية: تنسيق العمل بين الفرق المتخصصة لضمان تنفيذ المشروع بكفاءة وجودة عالية، مع التزام تام بالمعايير المهنية.",
    },
  },
  {
    id: 2,
    srcimg: "/assets/img/blog/2.jpg",
    title: {
      en: "Engineering and Architectural Design",
      ar: "التصميم الهندسي والمعماري",
    },
    day: "25",
    month: {
      en: "NOV",
      ar: "نوفمبر",
    },
    author: {
      en: "Admin",
      ar: "المدير",
    },
    comments: "0",
    descriptions: {
      en: "Elite Company offers engineering and architectural design services that combine beauty and functionality, including: Design of residential, commercial, and industrial buildings: integrated building design that meets client needs while considering different functions. Preparation of engineering plans according to client requirements: precise and professional plans that consider all details. Providing modern or classical designs according to client vision: architectural solutions combining modern and classical styles to suit client taste and needs. Providing 3D designs for project visualization before execution: using 3D imaging techniques to display the project realistically and clarify details before implementation.",
      ar: "تقدم شركة إيليت خدمات التصميم الهندسي والمعماري التي تجمع بين الجمال والوظيفة، ومنها: تصميم المباني السكنية، التجارية، والصناعية: تصميم مباني متكاملة تلبي احتياجات العملاء مع مراعاة الوظائف المختلفة لكل نوع. إعداد المخططات الهندسية وفقًا لمتطلبات العميل: إعداد مخططات دقيقة واحترافية تراعي كافة التفاصيل. توفير تصاميم عصرية أو كلاسيكية وفقًا لرؤية العميل: تصميم حلول معمارية تجمع بين الأسلوب العصري والكلاسيكي بما يتناسب مع ذوق العميل واحتياجاته. تقديم تصاميم ثلاثية الأبعاد (3D) لتصور المشروع قبل التنفيذ: استخدام تقنيات التصوير ثلاثي الأبعاد لعرض المشروع بشكل واقعي وتوضيح التفاصيل قبل البدء في التنفيذ.",
    },
  },
  {
    id: 3,
    srcimg: "/assets/img/blog/3.jpg",
    title: {
      en: "Construction Project Implementation",
      ar: "تنفيذ مشاريع البناء",
    },
    day: "24",
    month: {
      en: "NOV",
      ar: "نوفمبر",
    },
    author: {
      en: "Admin",
      ar: "المدير",
    },
    comments: "0",
    descriptions: {
      en: "Elite Contracting Company excels in executing various types of projects with precision and professionalism, including: Construction of luxury villas and residential complexes: executing distinguished residential projects, including luxury villas and integrated complexes according to the highest quality standards. Implementation of commercial buildings such as offices and shops: building modern design commercial offices and shops. Construction of warehouses and factories using the latest building technologies: to provide an efficient and safe work environment. Using high-quality building materials to ensure structural durability: relying on durable and high-quality building materials to ensure sustainability and structural strength for long periods.",
      ar: "تتميز شركة إيليت للمقاولات بقدرتها على تنفيذ مختلف أنواع المشاريع بدقة واحترافية، مثل: بناء الفلل الفاخرة والمجمعات السكنية: تنفيذ مشاريع سكنية متميزة، تضم فلل فاخرة ومجمعات متكاملة وفقًا لأعلى معايير الجودة. تنفيذ المباني التجارية مثل المكاتب والمحلات: بناء مكاتب تجارية ومحلات ذات تصميم عصري. إنشاء المستودعات والمصانع باستخدام أحدث تقنيات البناء: لتوفير بيئة عمل فعّالة وآمنة. استخدام مواد بناء عالية الجودة لضمان متانة الهيكل: الاعتماد على مواد بناء متينة وعالية الجودة لضمان استدامة وقوة الهيكل لفترات طويلة.",
    },
  },
  {
    id: 4,
    srcimg: "/assets/img/blog/4.jpg",
    title: {
      en: "Electrical and Mechanical Works",
      ar: "الأعمال الكهربائية والميكانيكية",
    },
    day: "23",
    month: {
      en: "NOV",
      ar: "نوفمبر",
    },
    author: {
      en: "Admin",
      ar: "المدير",
    },
    comments: "0",
    descriptions: {
      en: "The company provides integrated electrical and mechanical services to ensure residents' comfort and safety, including: Installation of electrical networks and control panels: implementing integrated electrical networks and installing control panels to ensure safe and effective electrical distribution. Installation of air conditioning and central cooling systems: designing and installing modern air conditioning and cooling systems to provide a comfortable building environment. Implementation of water and sewage systems: establishing efficient water and sewage systems to ensure comfort and safety. Installation of protection systems such as surveillance cameras and alarm systems: providing integrated protection systems including surveillance cameras and alarm devices to maintain facility security.",
      ar: "توفر الشركة خدمات متكاملة للأعمال الكهربائية والميكانيكية لضمان راحة وسلامة السكان، ومنها: تمديد الشبكات الكهربائية وتركيب لوحات التحكم: تنفيذ شبكات كهربائية متكاملة وتركيب لوحات تحكم لضمان توزيع كهربائي آمن وفعال. تركيب أنظمة التكييف والتبريد المركزي: تصميم وتركيب أنظمة تكييف وتبريد حديثة لتوفير بيئة مريحة للمباني. تنفيذ أنظمة المياه والصرف الصحي: إنشاء أنظمة مياه وصرف صحي تعمل بكفاءة لضمان الراحة والسلامة. تركيب أنظمة الحماية مثل كاميرات المراقبة وأنظمة الإنذار: توفير أنظمة حماية متكاملة تشمل كاميرات مراقبة وأجهزة إنذار للحفاظ على أمن المنشآت.",
    },
  },
  {
    id: 5,
    srcimg: "/assets/img/blog/5.jpg",
    title: {
      en: "Interior and Exterior Finishing",
      ar: "التشطيبات الداخلية والخارجية",
    },
    day: "22",
    month: {
      en: "NOV",
      ar: "نوفمبر",
    },
    author: {
      en: "Admin",
      ar: "المدير",
    },
    comments: "0",
    descriptions: {
      en: "Final finishes add beauty and the final touch to the project. Elite Company offers various options including: Installation of marble or ceramic flooring: selection of elegant and modern flooring to suit client taste. Implementation of paint works and gypsum decorations: professional painting and gypsum decorations that add aesthetic touch. Installation of high-quality doors and windows: durable and beautiful doors and windows to increase security and appearance. Exterior facade finishing: using stone, brick, or concrete to provide attractive and durable facades.",
      ar: "التشطيبات النهائية هي التي تضفي الجمال واللمسة الأخيرة على المشروع. تقدم شركة إيليت خيارات متنوعة تشمل: تركيب الأرضيات الرخامية أو السيراميك: اختيار أرضيات أنيقة وعصرية تناسب ذوق العميل. تنفيذ أعمال الدهانات والديكورات الجبسية: طلاء احترافي وديكورات جبسية تضفي لمسة جمالية. تركيب الأبواب والنوافذ عالية الجودة: أبواب ونوافذ متينة وجميلة لزيادة الأمان والمظهر. تشطيب الواجهات الخارجية: استخدام الحجر أو الطوب أو الخرسانة لإعطاء واجهات جذابة ومتينة.",
    },
  },
  {
    id: 6,
    srcimg: "/assets/img/blog/10.jpg",
    title: {
      en: "Building Renovation and Maintenance Services",
      ar: "خدمات ترميم وصيانة المباني",
    },
    day: "21",
    month: {
      en: "NOV",
      ar: "نوفمبر",
    },
    author: {
      en: "Admin",
      ar: "المدير",
    },
    comments: "0",
    descriptions: {
      en: "Elite Company's services are not limited to construction only, but also include renovation and maintenance of old buildings to renew them and ensure their efficiency, such as: Repairing cracks in walls and ceilings: treating cracks and fissures to ensure building stability and prevent deterioration. Improving thermal and water insulation systems: renovating insulation systems to ensure maintenance of internal temperatures and prevent water leakage. Updating old electrical and mechanical systems: replacing and maintaining old systems to provide better performance and increase safety.",
      ar: "لا تقتصر خدمات شركة إيليت على البناء فقط، بل تشمل أيضًا ترميم وصيانة المباني القديمة لتجديدها وضمان كفاءتها، مثل: إصلاح التشققات في الجدران والأسقف: معالجة التشققات والتصدعات لضمان استقرار المبنى ومنع تدهور حالته. تحسين أنظمة العزل الحراري والمائي: تجديد أنظمة العزل لضمان الحفاظ على درجات الحرارة الداخلية ومنع تسرب المياه. تحديث الأنظمة الكهربائية والميكانيكية القديمة: استبدال وصيانة الأنظمة القديمة لتوفير أداء أفضل وزيادة الأمان.",
    },
  },
  {
    id: 7,
    srcimg: "/assets/img/blog/6.jpg",
    title: {
      en: "Landscape and Outdoor Areas Design",
      ar: "تنسيق الحدائق والمسطحات الخارجية",
    },
    day: "20",
    month: {
      en: "NOV",
      ar: "نوفمبر",
    },
    author: {
      en: "Admin",
      ar: "المدير",
    },
    comments: "0",
    descriptions: {
      en: "The company focuses on providing an attractive and comfortable outdoor environment through landscaping services, including: Design and installation of gardens and green spaces: We design distinctive gardens according to client needs, selecting appropriate plants and coordinating spaces to add natural beauty and peaceful atmospheres. Construction of swimming pools and fountains: We specialize in creating pools and fountains with unique designs, providing water solutions that add a touch of luxury to outdoor spaces.",
      ar: "تهتم الشركة بتوفير بيئة خارجية جذابة ومريحة من خلال خدمات تنسيق الحدائق، ومنها: تصميم وتركيب الحدائق والمسطحات الخضراء: نقوم بتصميم حدائق مميزة وفقًا لاحتياجات العميل، مع اختيار النباتات المناسبة وتنسيق المساحات لإضفاء جمال طبيعي وأجواء هادئة. إنشاء المسابح والنوافير: نحن متخصصون في إنشاء المسابح ��النوافير ذات التصميمات الفريدة، مع توفير حلول مائية تضفي لمسة من الفخامة للمساحات الخارجية.",
    },
  },
  {
    id: 8,
    srcimg: "/assets/img/blog/7.jpg",
    title: {
      en: "Environmental Standards and Sustainability Commitment",
      ar: "الالتزام بالمعايير البيئية والاستدامة",
    },
    day: "19",
    month: {
      en: "NOV",
      ar: "نوفمبر",
    },
    author: {
      en: "Admin",
      ar: "المدير",
    },
    comments: "0",
    descriptions: {
      en: "Elite Company is committed to sustainable practices to preserve the environment and reduce negative impact, including: Use of environmentally friendly building materials: Elite Company relies on recyclable and harmful-free building materials to ensure minimal environmental impact. Improving energy efficiency in buildings: The company focuses on designing buildings in ways that contribute to reducing energy consumption, such as using thermal insulation and smart lighting technologies. Implementation of water recycling systems: The company applies innovative solutions for water reuse in buildings, reducing waste and enhancing environmental sustainability.",
      ar: "تلتزم شركة إيليت بالممارسات المستدامة للحفاظ على البيئة وتقليل التأثير السلبي، ومنها: استخدام مواد بناء صديقة للبيئة: تعتمد شركة إيليت على استخدام مواد بناء قابلة لإعادة التدوير وخالية من المواد الضارة لضمان تقليل التأثير البيئي. تحسين كفاءة استهلاك الطاقة في المباني: تركز الشركة على تصميم المباني بطرق تسهم في تقليل استهلاك الطاقة، مل استخدام العزل الحراري وتقنيات الإضاءة الذكية. تنفيذ أنظمة إعادة تدوير المياه: تقوم الشركة بتطبيق حلول مبتكرة لإعادة استخدام المياه في المباني، مما يقلل من الفاقد ويعزز من الاستدامة البيئية.",
    },
  },
  {
    id: 9,
    srcimg: "/assets/img/blog/8.jpg",
    title: {
      en: "Technical and Engineering Consultations",
      ar: "الاستشارات الفنية والهندسية",
    },
    day: "18",
    month: {
      en: "NOV",
      ar: "نوفمبر",
    },
    author: {
      en: "Admin",
      ar: "المدير",
    },
    comments: "0",
    descriptions: {
      en: "The company provides integrated consulting services to help clients make informed decisions at every stage of the project, such as: Selection of appropriate materials and equipment: We help clients identify the best materials and equipment that suit project needs to ensure quality and high performance. Construction quality assessment: We review and analyze the quality of construction work to ensure compliance with required standards and specifications. Providing innovative solutions to reduce costs and improve efficiency: We provide consultations aimed at reducing costs and improving project efficiency using innovative techniques and solutions.",
      ar: "تقدم الشركة خدمات استشارية متكاملة لمساعدة العملاء على اتخاذ قرارات مدروسة في كل مرحلة من مراحل المشروع، مثل: اختيار المواد والمعدات المناسبة: نساعد العملاء في تحديد أفضل المواد والمعدات التي تتناسب مع احتياجات المشروع لضمان الجودة والأداء العالي. تقييم جودة البناء: نقوم بمراجعة وتحليل جودة تنفيذ الأعمال الإنشائية لضمان الالتزام بالمعايير والمواصفات المطلوبة. تقديم حلول مبتكرة لتقليل التكاليف وتحسين الكفاءة: نقدم استشارات تهدف إلى تقليل التكاليف وتحسين كفاءة المشروع باستخدام تقنيات وحلول مبتكرة.",
    },
  },
  {
    id: 10,
    srcimg: "/assets/img/blog/9.jpg",
    title: {
      en: "Conclusion",
      ar: "خاتمة",
    },
    day: "18",
    month: {
      en: "NOV",
      ar: "نوفمبر",
    },
    author: {
      en: "Admin",
      ar: "المدير",
    },
    comments: "0",
    descriptions: {
      en: "When considering the implementation of a construction project, choosing a reliable and experienced contracting company is the key to ensuring project success. Elite Contracting Company in Riyadh combines experience, quality, and commitment to realize your vision and turn it into tangible reality. If you are looking for a construction partner who meets your aspirations with the highest levels of professionalism, do not hesitate to contact us.",
      ar: "عند التفكير في تنفيذ مشروع بناء، فإن اختيار شركة مقاولات موثوقة وذات خبرة هو المفتاح لضمان نجاح المشروع. شركة إيليت للمقاولات في الرياض تجمع بين الخبرة، الجودة، والالتزام لتحقيق رؤيتك وتحويلها إلى واقع ملموس. إذا كنت تبحث عن شريك بناء يلبي تطلعاتك بأعلى مستويات الاحترافية، فلا تتردد في التواصل معنا.",
    },
  },
];

export default blogData;
