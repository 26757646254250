export const resources = {
  en: {
    translation: {
      "Home": "Home",
      "About Us": "About Us",
      "Services": "Services",
      "Projects": "Projects",
      "Careers": "Careers",
      "Blog": "Blog",
      "Contact": "Contact",
      // القائمة الفرعية
      "Current Openings": "Current Openings",
      "Apply Now": "Apply Now",
      "Benefits": "Benefits"
    }
  },
  ar: {
    translation: {
      "Home": "الرئيسية",
      "About Us": "من نحن",
      "Services": "خدماتنا",
      "Projects": "المشاريع",
      "Careers": "التوظيف",
      "Blog": "المدونة",
      "Contact": "اتصل بنا",
      // القائمة الفرعية
      "Current Openings": "الوظائف المتاحة",
      "Apply Now": "تقدم الآن",
      "Benefits": "المميزات"
    }
  }
};
