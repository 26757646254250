import React from "react";
import { useTranslation } from "react-i18next";
import { FaTiktok, FaFacebookF, FaWhatsapp, FaInstagram } from "react-icons/fa";
import { FaSnapchat } from "react-icons/fa6";

const topBarData = {
  address: {
    en: "Khurais Br Rd, An Nasim Al Gharbi, Riyadh 14231, Saudi Arabia",
    ar: "طريق خريص الفرعي، النسيم الغربي، الرياض ١٤٢٣١، المملكة العربية السعودية",
  },
  phone: "+966 50 955 3440",

  socialFollow: {
    en: "Follow us:",
    ar: "تابعنا:",
  },
};

const TopBar = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  return (
    <article
      id="top-bar"
      style={{
        backgroundColor: "rgb(7, 11, 34)",
        height: "45px",
        marginTop: "-4px",
      }}
    >
      <article id="top-bar-inner" className="container">
        <article className="top-bar-inner-wrap">
          <article className="top-bar-content">
            <article className="inner" style={{ color: "#fff" }}>
              <span
                className="address content"
                title={topBarData.address[currentLang]}
              >
                {topBarData.address[currentLang]}
              </span>
              <span className="phone content" title={topBarData.phone}>
                {topBarData.phone}
              </span>
            </article>
          </article>
          <article className="top-bar-socials">
            <article className="inner">
              <span className="text" style={{ color: "#fff" }}>
                {topBarData.socialFollow[currentLang]}
              </span>
              <span className="icons">
                <a
                  href="https://www.tiktok.com/@elitearabcontractors"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#fff" }}
                >
                  <FaTiktok size={16} />
                </a>
                <a
                  href="https://www.snapchat.com/add/rehlatalsalamco?share_id=LdpDKAcJkiQ&locale=ar-EG"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#fff" }}
                >
                  <FaSnapchat size={16} />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61565901014891&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#fff" }}
                >
                  <FaFacebookF size={16} />
                </a>
                <a
                  href="https://www.instagram.com/elitearab2/?igsh=aGg2MXc4OGd1MXY%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#fff" }}
                >
                  <FaInstagram size={16} />
                </a>
                <a
                  href="https://wa.me/966509553440"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#fff" }}
                >
                  <FaWhatsapp size={16} />
                </a>
              </span>
            </article>
          </article>
        </article>
      </article>
    </article>
  );
};

export default TopBar;
