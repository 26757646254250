export const projectData = [
  {
    id: 45,
    classnames: "project-item green villa",
    srcimg: "assets/img/project/25.png",
    heading: { en: "U-Plaza Commercial Project", ar: "مشروع يو بلازا التجاري" },
    type: { en: "commercial", ar: "تجاري" },
    children: [
      {
        id: 46,
        classnames: "project-item green villa",
        srcimg: "assets/img/project/26.png"
      },
      {
        id: 38,
        classnames: "project-item architecture building",
        srcimg: "assets/img/project/019.png",
      },
      {
        id: 47,
        classnames: "project-item architecture building",
        srcimg: "assets/img/project/27.png"
      },
      {
        id: 48,
        classnames: "project-item villa building",
        srcimg: "assets/img/project/28.png"
      },
      {
        id: 49,
        classnames: "project-item green architecture",
        srcimg: "assets/img/project/29.png"
      },
      {
        id: 50,
        classnames: "project-item villa building",
        srcimg: "assets/img/project/30.png"
      },
      {
        id: 51,
        classnames: "project-item architecture building",
        srcimg: "assets/img/project/31.png"
      },
      {
        id: 52,
        classnames: "project-item green villa",
        srcimg: "assets/img/project/32.png"
      },
      {
        id: 53,
        classnames: "project-item villa building",
        srcimg: "assets/img/project/33.png"
      }
    ]
  },
  {
    id: 4,
    classnames: "project-item green villa",
    srcimg: "assets/img/project/03.png",
    heading: { en: "Dr. Azzam Al-Ruhaimi Villa", ar: "فلة الدكتور عزام الرحيمين" },
    type: { en: "villa", ar: "فيلا" },
    children: [
      {
        id: 7,
        classnames: "project-item villa architecture",
        srcimg: "assets/img/project/04.png"
      }
    ]
  },
  {
    id: 14,
    classnames: "project-item villa building",
    srcimg: "assets/img/project/07.png",
    heading: { en: "Prince Mohammed bin Sultan bin Abdulaziz Palace", ar: "قصر الأمير محمد بن سلطان بن عبدالعزيز" },
    type: { en: "palace", ar: "قصر" },
    children: [
      {
        id: 18,
        classnames: "project-item green villa",
        srcimg: "assets/img/project/09.png"
      }
    ]
  },
  {
    id: 5,
    classnames: "project-item architecture building",
    srcimg: "assets/img/project/3.png",
    heading: { en: "Dr. Fayez Azzam Villa", ar: "فيلا الدكتور فايز عزام" },
    type: { en: "villa", ar: "فيلا" },
  },
  {
    id: 8,
    classnames: "project-item villa building",
    srcimg: "assets/img/project/4.png",
    heading: { en: "Ahmed Al-Qassimi Villa", ar: "فيلا أحمد القصيمي" },
    type: { en: "villa", ar: "فيلا" },
    children: [
      {
        id: 9,
        classnames: "project-item green architecture",
        srcimg: "assets/img/project/5.png",
       
      },
    ]
  },
  
  {
    id: 10,
    classnames: "project-item villa building",
    srcimg: "assets/img/project/05.png",
    heading: { en: "Al-Jubair Kings Villa", ar: "فلة ملوك الجبير" },
    type: { en: "villa", ar: "فيلا" },
    children: [
      {
        id: 12,
        classnames: "project-item green villa",
        srcimg: "assets/img/project/06.png"
      }
    ]
  },
  {
    id: 11,
    classnames: "project-item architecture building",
    srcimg: "assets/img/project/6.png",
    heading: { en: "Fahad Al-Rashid Villa", ar: "فيلا فهد الراشد" },
    type: { en: "villa", ar: "فيلا" },
  },
  {
    id: 13,
    classnames: "project-item architecture building",
    srcimg: "assets/img/project/7.png",
    heading: { en: "Khalid Al-Jeddawi Villa", ar: "فيلا خالد الجداوي" },
    type: { en: "villa", ar: "فيلا" },
  },

  {
    id: 15,
    classnames: "project-item green architecture",
    srcimg: "assets/img/project/8.png",
    heading: { en: "Saleh Al-Dammam Villa", ar: "فيلا صالح الدمام" },
    type: { en: "villa", ar: "فيلا" },
  },
  {
    id: 16,
    classnames: "project-item villa building",
    srcimg: "assets/img/project/08.png",
    heading: { en: "Sheikh Nayef Al-Qahtani Palace", ar: "قصر الشيخ نايف القحطاني" },
    type: { en: "palace", ar: "قصر" },
    children: [
      {
        id: 20,
        classnames: "project-item villa building",
        srcimg: "assets/img/project/010.png"
      }
    ]
  },
  {
    id: 17,
    classnames: "project-item architecture building",
    srcimg: "assets/img/project/9.png",
    heading: { en: "Sultan Al-Qahtani Villa", ar: "فلة سلطان القحطاني" },
    type: { en: "villa", ar: "فيلا" },
  },
  {
    id: 19,
    classnames: "project-item architecture building",
    srcimg: "assets/img/project/10.png",
    heading: { en: "Ibrahim Al-Makki Restaurant", ar: "مطعم إبراهيم المكي" },
    type: { en: "Restaurant", ar: "مطعم" },
  },
  {
    id: 22,
    classnames: "project-item villa building",
    srcimg: "assets/img/project/011.png",
    heading: { en: "Al-Amal Hospital Interior Finishes", ar: "مستشفى الأمل تشطيبات داخلية" },
    type: { en: "hospital", ar: "مستشفى" },
    children: [
      {
        id: 24,
        classnames: "project-item green villa",
        srcimg: "assets/img/project/012.png"
      }
    ]
  },
  {
    id: 23,
    classnames: "project-item architecture building",
    srcimg: "assets/img/project/12.png",
    heading: { en: "Yousef Al-Khobar Villa", ar: "فيلا يوسف الخبر" },
    type: { en: "villa", ar: "فيلا" },
  },
  {
    id: 25,
    classnames: "project-item architecture building",
    srcimg: "assets/img/project/13.png",
    heading: { en: "Majid Al-Abha Villa", ar: "فيلا ماجد الأبها" },
    type: { en: "villa", ar: "فيلا" },
  },
  {
    id: 26,
    classnames: "project-item villa building",
    srcimg: "assets/img/project/013.png",
    heading: { en: "Al-Qahtani Villa", ar: "فيلا القحطاني" },
    type: { en: "villa", ar: "فيلا" },
  },
  {
    id: 27,
    classnames: "project-item green architecture",
    srcimg: "assets/img/project/14.png",
    heading: { en: "Salem Al-Najrani Villa", ar: "فيلا سالم النجراني" },
    type: { en: "villa", ar: "فيلا" },
  },
  {
    id: 28,
    classnames: "project-item villa building",
    srcimg: "assets/img/project/014.png",
    heading: { en: "Al-Shammari Estate", ar: "قصر آل شمر" },
    type: { en: "palace", ar: "قصر" },
  },
  {
    id: 29,
    classnames: "project-item architecture building",
    srcimg: "assets/img/project/15.png",
    heading: { en: "Omar Al-Taifi Villa", ar: "فيلا عمر الطائفي" },
    type: { en: "villa", ar: "فيلا" },
  },
  {
    id: 30,
    classnames: "project-item green villa",
    srcimg: "assets/img/project/015.png",
    heading: { en: "Al-Anazi Villa", ar: "فيلا العنزي" },
    type: { en: "villa", ar: "فيلا" },
  },
  {
    id: 32,
    classnames: "project-item architecture building",
    srcimg: "assets/img/project/16.png",
    heading: { en: "Interior Finishes Al Jazeera Hospital", ar: "تشطيبات داخلية مستشفى الجزيرة" },
    type: { en: "hospital", ar: "مستشفى" },
  },
  {
    id: 33,
    classnames: "project-item green villa",
    srcimg: "assets/img/project/017.png",
    heading: { en: "Al-Safa Investment Company", ar: "شركة الصفا للاستثمار" },
    type: { en: "commercial", ar: "تجاري" },
  },
  {
    id: 34,
    classnames: "project-item architecture building",
    srcimg: "assets/img/project/17.png",
    heading: { en: "Abdulaziz Al-Jubaili Villa", ar: "فيلا عبدالعزيز الجبيلي" },
    type: { en: "villa", ar: "فيلا" },
  },
  {
    id: 35,
    classnames: "project-item villa building",
    srcimg: "assets/img/project/18.png",
    heading: { en: "Al-Yami Residence", ar: "فيلا اليامي" },
    type: { en: "villa", ar: "فيلا" },
  },
  {
    id: 37,
    classnames: "project-item villa building",
    srcimg: "assets/img/project/19.png",
    heading: { en: "Al-Zahir Complex", ar: "فيلا الظاهر" },
    type: { en: "villa", ar: "فيلا" },
  },

  {
    id: 39,
    classnames: "project-item green villa",
    srcimg: "assets/img/project/20.png",
    heading: { en: "Al-Maliki villa", ar: "فيلا المالكي" },
    type: { en: "villa", ar: "فيلا" },
  },
  {
    id: 41,
    classnames: "project-item architecture building",
    srcimg: "assets/img/project/21.png",
    heading: { en: "Jazan Cultural Hub", ar: "فيلا جازان الثقافي" },
    type: { en: "villa", ar: "فيلا" },
  },
  {
    id: 42,
    classnames: "project-item green villa",
    srcimg: "assets/img/project/021.png",
    heading: { en: "Al-Mutairi Villa", ar: "فيلا المطيري" },
    type: { en: "villa", ar: "فيلا" },
    children: [
      {
        id: 40,
        classnames: "project-item villa building",
        srcimg: "assets/img/project/020.png",
      },
    ]
  },
  {
    id: 43,
    classnames: "project-item villa building",
    srcimg: "assets/img/project/22.png",
    heading: { en: "Al-Sulami Villa", ar: "فيلا السلمي" },
    type: { en: "villa", ar: "فيلا" },
  },
  {
    id: 44,
    classnames: "project-item architecture building",
    srcimg: "assets/img/project/23.png",
    heading: { en: "Baha Mountain Villa", ar: "فيلا الباحة الجبلية" },
    type: { en: "Villa", ar: "فيلا" },
  },
  
];
