import React from "react";

const overviewData = [
  {
    id: 1,
    title: {
      en: "Our focus",
      ar: "تركيزنا",
    },
    text: {
      en: "Our focus is to exceed your goals, meet your timelines and successfully manage your budget. We provide our clients with top-notch general contracting, construction management, site surveys and design build services.",
      ar:"نحن نركز على مساعدتك في تجاوز أهدافك وتلبية جداولك الزمنية وإدارة ميزانيتك بنجاح. نقدم لعملائنا مجموعة متنوعة من الخدمات المتميزة، تشمل"
    },
    items: [
      {
        id: 1,
        content: {
          en: "General Contracting",
          ar: "المقاولات العامة",
        },
      },
      {
        id: 2,
        content: {
          en: "Construction Management",
          ar: "إدارة البناء",
        },
      },
      {
        id: 3,
        content: {
          en: "Site Surveys",
          ar: "المسح الموقعي",
        },
      },
      {
        id: 4,
        content: {
          en: "Design and Build Services",
          ar: "خدمات التصميم والبناء",
        },
      }
    ],
  },
  {
    id: 2,
    title: {
      en: "Dedicated",
      ar: "التفاني",
    },
    text: {
      en: "Our dedicated team of professionals ensures the highest quality standards in every project. We combine expertise with innovation to deliver exceptional results that meet and exceed client expectations.",
      ar: "جميع خدماتنا تُنفَّذ بأعلى المعايير والاحترافية، لضمان تحقيق النجاح والتميز في كل مشروع.",
    },
    items: [
      {
        id: 1,
        content: {
          en: "Professional team with extensive experience in construction and development.",
          ar: "فريق محترف ذو خبرة واسعة في مجال البناء والتطوير.",
        },
      },
      {
        id: 2,
        content: {
          en: "Commitment to delivering projects on time and within budget.",
          ar: "الالتزام بتسليم المشاريع في الوقت المحدد وضمن الميزانية.",
        },
      },
      {
        id: 3,
        content: {
          en: "Continuous development and adoption of latest construction technologies.",
          ar: "التطوير المستمر وتبني أحدث تقنيات البناء.",
        },
      },
    ],
  },
  {
    id: 3,
    title: {
      en: "Committed",
      ar: "الالتزام",
    },
    text: {
      en: "We are committed to excellence in every aspect of our work. Our team works diligently to ensure that each project is executed with precision and attention to detail.",
      ar: "نحن ملتزمون بتحقيق التميز في جميع جوانب عملنا، حيث يعمل فريقنا بقوة لضمان تنفيذ كل مشروع بدقة عالية واهتمام فائق بالتفاصيل.",
    },
    items: [
      {
        id: 1,
        content: {
          en: "We prioritize customer satisfaction and project success.",
          ar: "نضع رضا العملاء ونجاح المشاريع في مقدمة أولوياتنا.",
        },
      },
      {
        id: 2,
        content: {
          en: "Strong commitment to international quality standards and local regulations.",
          ar: "التزام قوي بمعايير الجودة العالمية واللوائح المحلية.",
        },
      },
      {
        id: 3,
        content: {
          en: "Building long-term relationships with our clients based on trust and reliability.",
          ar: "نحرص على بناء علاقات طويلة الأمد مع عملائنا، مبنية على الثقة والموثوقية.",
        },
      },
    ],
  },
];

const Overview = ({ currentLang = "en" }) => {
  return (
    <article className="col-md-6">
      <article
        className="themesflat-content-box clearfix"
        data-margin="0 18px 0 0"
        data-mobilemargin="0 0 0 0"
      >
        <article className="themesflat-headings style-1 clearfix">
          <h2
            className="heading"
            style={{ textAlign: currentLang === "ar" ? "right" : "left" }}
          >
            {currentLang === "en" ? "OVERVIEW" : "نظرة عامة"}
          </h2>
          <article
            className={`sep has-width w80 accent-bg margin-top-11 clearfix ${
              currentLang === "ar" ? "float-right" : ""
            }`}
          />
        </article>
        <article
          className="themesflat-spacer clearfix"
          data-desktop={38}
          data-mobile={35}
          data-smobile={35}
        />
        <article
          className="themesflat-tabs style-1 clearfix"
          style={{ direction: currentLang === "ar" ? "rtl" : "ltr" }}
        >
          <ul
            className="tab-title clearfix"
            style={{
              display: "flex",
              flexDirection: currentLang === "ar" ? "row-reverse" : "row",
              justifyContent: currentLang === "ar" ? "flex-end" : "flex-start",
            }}
          >
            {overviewData.map((tab) => (
              <li
                className={`item-title ${tab.id === 1 ? "active" : ""}`}
                key={tab.id}
              >
                <span className="inner">{tab.title[currentLang]}</span>
              </li>
            ))}
          </ul>
          <article className="tab-content-wrap clearfix">
            {overviewData.map((data) => (
              <article
                className="tab-content"
                key={data.id}
                style={{ direction: currentLang === "ar" ? "rtl" : "ltr" }}
              >
                <article
                  className="item-content"
                  style={{ textAlign: currentLang === "ar" ? "right" : "left" }}
                >
                  <p
                    style={{
                      textAlign: currentLang === "ar" ? "right" : "left",
                      fontSize: currentLang === "ar" && "1.5rem",
                      fontFamily: currentLang === "ar" && "bold",
                      color:"#000"
                    }}
                  >
                    {data.text[currentLang]}
                  </p>
                  {data.items.map((item) => (
                    <article
                      className={`has-icon style-1 icon-left ofset-padding-32 clearfix ${
                        currentLang === "en" && "themesflat-list "
                      }`}
                      key={item.id}
                    >
                      <article className="inner">
                        <span
                          className="item"
                          style={{
                            display: "flex",
                            flexDirection:
                              currentLang === "ar" ? "row-reverse" : "row",
                            justifyContent:
                              currentLang === "ar" ? "flex-end" : "flex-start",
                            textAlign: currentLang === "ar" ? "right" : "left",
                            marginTop: currentLang === "ar" ? "20px" : "0",
                          }}
                        >
                          <span
                            className="text"
                            style={{
                              fontSize: currentLang === "ar" && "1.5rem",
                              fontFamily: currentLang === "ar" && "bold",
                            }}
                          >
                            {item.content[currentLang]}
                          </span>
                          <span
                            className="icon"
                            style={{
                              marginRight: currentLang === "ar" ? "0" : "10px",
                              marginLeft: currentLang === "ar" ? "10px" : "0",
                              color: "#dda939",
                            }}
                          >
                            <i className="fa fa-check-square" />
                          </span>
                        </span>
                      </article>
                    </article>
                  ))}
                </article>
              </article>
            ))}
          </article>
        </article>
      </article>
    </article>
  );
};

export default Overview;
