import React from "react";
import { Link } from "react-router-dom";
import {
  Slider,
  TitleHeading,
  About,
  Project,
  ServicesBox,
  Partner,
} from "../layouts/home01/index";
import { Header, Footer, TopBar, BottomBar } from "../layouts/general/index";
import { useTranslation } from "react-i18next";
const pageData = {
  headers: [
    {
      id: 1,
      logoweb: "assets/img/logo-small.png",
      names: {
        en: "Home",
        ar: "الرئيسية",
      },
    },
  ],
};

const headingproject = [
  {
    id: 1,
    classnames: "heading text-white",
    title: {
      en: "FEATURED PROJECTS",
      ar: "مشاريعنا المميزة",
    },
    classtext: "sub-heading text-white",
  },
];

const servicebox = [
  {
    id: 1,
    classnames: "heading font-size-30",
    title: {
      en: "ALL SERVICES",
      ar: "جميع الخدمات",
    },
    classtext: "sub-heading font-weight-400",
    text: {
      en: "Are you interested in finding out how we can make your project a success? Please contact us.",
      ar: "هل تريد معرفة كيف يمكننا أن نجعل مشروعك ناجحاً؟ يرجى الاتصال بنا.",
    },
  },
];

const projectsButtonText = {
  en: "ALL PROJECTS",
  ar: "جميع المشاريع",
};

const executeProjectText = {
  en: "START EXECUTING YOUR PROJECT",
  ar: "ابدأ تنفيذ مشروعك",
};

const Home01 = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  return (
    <article className="header-fixed page no-sidebar header-style-2 topbar-style-2 menu-has-search">
      <article id="wrapper" className="animsition">
        <article id="page" className="clearfix">
          <article id="site-header-wrap">
            <TopBar />
            {pageData.headers.map((data, idx) => (
              <Header data={data} key={idx} />
            ))}
          </article>
          <article id="main-content" className="site-main clearfix">
            <article id="content-wrap">
              <article id="site-content" className="site-content clearfix">
                <article id="inner-content" className="inner-content-wrap">
                  <article className="page-content">
                    <article className="rev_slider_wrapper fullwidthbanner-container">
                      <article
                        id="rev-slider1"
                        className="rev_slider fullwidthabanner"
                      >
                        <Slider />
                      </article>
                    </article>
                    <About />
                    <article className="row-iconbox">
                      <article className="container">
                        <article className="row">
                          <article className="col-md-12">
                            <article
                              className="themesflat-spacer clearfix"
                              data-desktop={61}
                              data-mobile={60}
                              data-smobile={60}
                            />
                            {servicebox.map((data) => (
                              <TitleHeading
                                data={data}
                                key={data.id}
                                show={true}
                              />
                            ))}
                            <article
                              className="themesflat-spacer clearfix"
                              data-desktop={57}
                              data-mobile={35}
                              data-smobile={35}
                            />
                            <ServicesBox currentLang={currentLang} />
                            <article className="row">
                              <article className="col-md-12">
                                <article
                                  className="themesflat-spacer clearfix"
                                  data-desktop={72}
                                  data-mobile={60}
                                  data-smobile={60}
                                />
                              </article>
                            </article>
                          </article>
                        </article>
                      </article>
                    </article>
                    <article className="row-project parallax parallax-1 parallax-overlay">
                      <article className="project-overlay">
                        <article className="container-fluid">
                          <article className="row">
                            <article className="col-md-12">
                              <article
                                className="themesflat-spacer clearfix"
                                data-desktop={60}
                                data-mobile={60}
                                data-smobile={60}
                              />
                              {headingproject.map((data) => (
                                <TitleHeading
                                  data={data}
                                  key={data.id}
                                  show={true}
                                />
                              ))}
                              <article
                                className="themesflat-spacer clearfix"
                                data-desktop={30}
                                data-mobile={35}
                                data-smobile={35}
                              />

                              <Project currentLang={currentLang} />
                              <article
                                className="themesflat-spacer clearfix"
                                data-desktop={41}
                                data-mobile={35}
                                data-smobile={35}
                              />
                              <article className="elm-button text-center">
                                <Link
                                  to="/project"
                                  onClick={() => {
                                    window.location.hash = "/project";
                                    window.location.reload();
                                  }}
                                  className="themesflat-button bg-accent"
                                >
                                  {projectsButtonText[currentLang]}
                                </Link>
                              </article>
                              <article
                                className="themesflat-spacer clearfix"
                                data-desktop={73}
                                data-mobile={60}
                                data-smobile={60}
                              />
                            </article>
                          </article>
                        </article>
                      </article>
                    </article>
                    <article className="row-quote bg-row-1">
                      <article className="container">
                        <article className="row">
                          <article className="col-md-12">
                            <article
                              className="themesflat-spacer clearfix"
                              data-desktop={40}
                              data-mobile={60}
                              data-smobile={60}
                            />
                            <article className="themesflat-quote style-1 clearfix">
                              <article className="quote-item">
                                <article className="inner">
                                  <article className="heading-wrap">
                                    <h3
                                      className="heading"
                                      style={{ color: "#0b0b22" }}
                                    >
                                      {executeProjectText[currentLang]}
                                    </h3>
                                  </article>
                                  <article className="button-wrap has-icon icon-left">
                                    <Link
                                      onClick={() => {
                                        window.location.hash = "/contact-01";
                                        window.location.reload();
                                      }}
                                      to="/contact-01"
                                      className="themesflat-button bg-white small"
                                    >
                                      <span>
                                        +966 50 955 3440
                                        <span className="icon">
                                          <i className="autora-icon-phone-contact" />
                                        </span>
                                      </span>
                                    </Link>
                                  </article>
                                </article>
                              </article>
                            </article>
                            <article
                              className="themesflat-spacer clearfix"
                              data-desktop={31}
                              data-mobile={60}
                              data-smobile={60}
                            />
                          </article>
                        </article>
                      </article>
                    </article>
                    <Partner />
                  </article>
                </article>
              </article>
            </article>
          </article>
          <Footer currentLang={currentLang} />
          <BottomBar currentLang={currentLang} activePage="home" />
        </article>
      </article>
    </article>
  );
};

export default Home01;
