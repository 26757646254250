import React from "react";
import { Link } from "react-router-dom";

const bottomBarData = {
	copyright: {
		en: {
			text: "© Construction Template. Design by",
			highlight: "ELITE",
		},
		ar: {
			text: "© قالب البناء. تصميم بواسطة",
			highlight: "إيليت",
		},
	},
	menus: [
		{
			id: 1,
			name: {
				en: "Home",
				ar: "الرئيسية",
			},
			links: "/",
		},
		{
			id: 2,
			name: {
				en: "About Us",
				ar: "من نحن",
			},
			links: "/about-us",
		},
		{
			id: 3,
			name: {
				en: "Services",
				ar: "خدماتنا",
			},
			links: "/services",
		},
		{
			id: 4,
			name: {
				en: "Projects",
				ar: "المشاريع",
			},
			links: "/project",
		},
		{
			id: 5,
			name: {
				en: "Pages",
				ar: "الصفحات",
			},
			links: "/page-testimonial",
		},
		{
			id: 6,
			name: {
				en: "Blog",
				ar: "المدونة",
			},
			links: "/blog",
		},
		{
			id: 7,
			name: {
				en: "Contact",
				ar: "اتصل بنا",
			},
			links: "/contact-01",
		},
	],
};

const BottomBar = ({ currentLang = "en", activePage = "" }) => {
	// التحقق من وجود النصوص قبل استخدامها
	const copyrightText = bottomBarData.copyright?.[currentLang]?.text || "";
	const copyrightHighlight =
		bottomBarData.copyright?.[currentLang]?.highlight || "";

	return (
		<article id="bottom" className="clearfix has-spacer">
			<article id="bottom-bar-inner" className="container">
				<article className="bottom-bar-inner-wrap">
					<article className="bottom-bar-content">
						<article id="copyright">
							{copyrightText}{" "}
							<span style={{ color: "#ffd658" }}>{copyrightHighlight}</span>
						</article>
					</article>
					<article className="bottom-bar-menu">
						<ul className="bottom-nav">
							{bottomBarData.menus.map((item) => (
								<li
									key={item.id}
									className={`menu-item ${
										activePage === item.name.en.toLowerCase()
											? "current-menu-item"
											: ""
									}`}
								>
									<Link
										to={item.links}
										onClick={() => {
											window.location.hash = item.links;
										}}
									>
										{item.name[currentLang]}
									</Link>
								</li>
							))}
						</ul>
					</article>
				</article>
			</article>
		</article>
	);
};

export default BottomBar;
