import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bannerhome1 from "./data_banner";

const Slider = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const getTextPosition = () => {
    return currentLang === "ar" ? "right" : "left";
  };

  const getHoffsetValue = () => {
    const screenWidth = window.innerWidth;

    if (currentLang === "ar") {
      if (screenWidth >= 1200) return "-1234";
      if (screenWidth >= 992) return "-900";
      if (screenWidth >= 768) return "-600";
      if (screenWidth >= 576) return "-440";
      return "-300";
    } else {
      if (screenWidth >= 1200) return "34";
      if (screenWidth >= 992) return "25";
      if (screenWidth >= 768) return "20";
      if (screenWidth >= 576) return "15";
      return "10";
    }
  };

  const getImageSrc = (item) => {
    return currentLang === "ar" ? item.srcimg2 : item.srcimg;
  };

  const getTextAlignment = () => {
    return currentLang === "ar" ? "text-right" : "text-left";
  };

  const getDirection = () => {
    return currentLang === "ar" ? "rtl" : "ltr";
  };

  React.useEffect(() => {
    let timeoutId;

    const handleResize = () => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        window.location.reload();
      }, 500); 
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
		<ul className="slider-container" dir={getDirection()}>
			{bannerhome1.map((data) => (
				<li data-transition="random" key={data.id} className="slider-item">
					<article className="image-slider">
						<img
							loading="lazy"
							src={getImageSrc(data)}
							alt="مقاول بنيان ملاحق - مقاول مجالس - مقاول ترميم بالرياض"
							className="img-slider"
						/>
					</article>
					<article>
						<article
							className={`${
								data.classnames
							} ${getTextAlignment()} slider-title animated fadeInDown`}
							data-x={getTextPosition()}
							data-hoffset={getHoffsetValue()}
							data-y="['middle','middle','middle','middle']"
							data-voffset={data.datavset1}
							data-fontsize="['58','52','42','36']"
							data-lineheight="['100','90','70','50']"
							data-width="full"
							data-height="none"
							data-whitespace="normal"
							data-transform_idle="o:1;"
							data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
							data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
							data-mask_in="x:0px;y:[100%];"
							data-mask_out="x:inherit;y:inherit;"
							data-start={700}
							data-splitin="none"
							data-splitout="none"
							data-responsive_offset="on"
							style={{ paddingBottom: "10px", fontWeight: "bold" }}
						>
							{data.title[currentLang]}
						</article>
						<article
							className={`${
								data.classtext
							} ${getTextAlignment()} slider-description-1 animated fadeInUp delay-1s`}
							data-x={getTextPosition()}
							data-hoffset={getHoffsetValue()}
							data-y="['middle','middle','middle','middle']"
							data-voffset={data.datavset2}
							data-fontsize="['32','28','24','20']"
							data-lineheight="['44','40','36','32']"
							data-width="full"
							data-height="none"
							data-whitespace="normal"
							data-transform_idle="o:1;"
							data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
							data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
							data-mask_in="x:0px;y:[100%];"
							data-mask_out="x:inherit;y:inherit;"
							data-start={1000}
							data-splitin="none"
							data-splitout="none"
							data-responsive_offset="on"
							style={{ paddingBottom: "5px" }}
						>
							{data.description1[currentLang]}
						</article>
						<article
							className={`${
								data.classtext
							} ${getTextAlignment()} slider-description-2 animated fadeInUp delay-2s`}
							data-x={getTextPosition()}
							data-hoffset={getHoffsetValue()}
							data-y="['middle','middle','middle','middle']"
							data-voffset={data.datavset3}
							data-fontsize="['32','28','24','20']"
							data-lineheight="['44','40','36','32']"
							data-width="full"
							data-height="none"
							data-whitespace="normal"
							data-transform_idle="o:1;"
							data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
							data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
							data-mask_in="x:0px;y:[100%];"
							data-mask_out="x:inherit;y:inherit;"
							data-start={1000}
							data-splitin="none"
							data-splitout="none"
							data-responsive_offset="on"
							style={{ paddingBottom: "5px" }}
						>
							{data.description2[currentLang]}
						</article>
						<article
							className={`${
								data.classbtn
							} ${getTextAlignment()} slider-button animated fadeInUp delay-3s`}
							data-x={getTextPosition()}
							data-hoffset={getHoffsetValue()}
							data-y="['middle','middle','middle','middle']"
							data-voffset={data.datavset}
							data-width="full"
							data-height="none"
							data-whitespace="normal"
							data-transform_idle="o:1;"
							data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
							data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
							data-mask_in="x:0px;y:[100%];"
							data-mask_out="x:inherit;y:inherit;"
							data-start={1000}
							data-splitin="none"
							data-splitout="none"
							data-responsive_offset="on"
							style={{ marginTop: "30px" }}
						>
							<Link
								to="/contact-01"
								onClick={() => {
									window.location.hash = "/contact-01";
									window.location.reload();
								}}
								className="themesflat-button bg-accent big hover-effect"
							>
								<span className="button-text">
									{data.buttonText[currentLang]}
								</span>
							</Link>
						</article>
					</article>
				</li>
			))}
		</ul>
	);
};

export default Slider;
